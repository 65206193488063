export var FIELD_TYPE;
(function (FIELD_TYPE) {
    FIELD_TYPE["DATE"] = "DATE";
    FIELD_TYPE["TEXT"] = "TEXT";
})(FIELD_TYPE || (FIELD_TYPE = {}));
export var LOAN_STATUS;
(function (LOAN_STATUS) {
    LOAN_STATUS["APPLIED"] = "APPLIED";
    LOAN_STATUS["PRE_APPROVED"] = "PRE_APPROVED";
    LOAN_STATUS["SUBMITTED"] = "SUBMITTED";
    LOAN_STATUS["REJECT"] = "REJECT";
    LOAN_STATUS["PENDING"] = "PENDING";
    LOAN_STATUS["WAITING_FOR_CONTACT"] = "WAITING_FOR_CONTACT";
    LOAN_STATUS["MANUAL_CANCEL"] = "MANUAL_CANCEL";
    LOAN_STATUS["UTILIZE"] = "UTILIZE";
    LOAN_STATUS["AUTOMATIC_CANCEL"] = "AUTOMATIC_CANCEL";
    LOAN_STATUS["EXPIRED"] = "EXPIRED";
    LOAN_STATUS["HARD_CASE"] = "HARD_CASE";
    LOAN_STATUS["APPLICATION_EXPIRED"] = "APPLICATION_EXPIRED";
    LOAN_STATUS["CANCEL"] = "CANCEL";
    LOAN_STATUS["CALL_API_SUCCESS"] = "CALL_API_SUCCESS";
})(LOAN_STATUS || (LOAN_STATUS = {}));
