var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { authenToken, createChocoToken, verifyLineId } from '@/pages/line-oa/api/rd-service';
import * as _ from 'lodash';
var _a = window || {}, _b = _a.reward, reward = _b === void 0 ? {} : _b, liff = _a.liff;
export var ChocoVehicleType;
(function (ChocoVehicleType) {
    ChocoVehicleType[ChocoVehicleType["NEWCAR"] = 1] = "NEWCAR";
    ChocoVehicleType[ChocoVehicleType["USEDCAR"] = 2] = "USEDCAR";
    ChocoVehicleType[ChocoVehicleType["MOTORCYCLE"] = 3] = "MOTORCYCLE";
    ChocoVehicleType[ChocoVehicleType["BIGBIKE"] = 4] = "BIGBIKE";
})(ChocoVehicleType || (ChocoVehicleType = {}));
export var ChocoRedirectPageType;
(function (ChocoRedirectPageType) {
    ChocoRedirectPageType["HOME"] = "Home";
    ChocoRedirectPageType["PRIVILEGE"] = "Privilege";
})(ChocoRedirectPageType || (ChocoRedirectPageType = {}));
export var buildState = function () { return ({
    profile: {},
    login: false,
    loading: false,
    infomation: null,
    authenToken: null,
    isError: null,
    redirectToken: null,
    context: null,
    lineUserId: '',
}); };
export var mutations = {
    updateProfile: function (state, value) {
        state.profile = value;
    },
    updateLineLoggedIn: function (state, value) {
        state.login = value;
    },
    setLoading: function (state, value) {
        state.loading = value;
    },
    updateInfomation: function (state, value) {
        state.infomation = value;
    },
    updateToken: function (state, value) {
        state.authenToken = value;
    },
    setError: function (state, value) {
        state.isError = value;
    },
    updateChocoToken: function (state, value) {
        state.redirectToken = value;
    },
    setContext: function (state, value) {
        state.context = value;
        var lineId = _.get(value, 'userId', 'U977c785d17b71e0c5b10b5f65285ca9a');
        state.lineUserId = lineId;
    },
};
export var getters = {
    getProfile: function (state) {
        return state.profile;
    },
    getInfomation: function (state) {
        return _.get(state, 'infomation', null);
    },
    isLineLoggedIn: function (state) {
        return state.login;
    },
    isLoading: function (state) {
        return state.loading;
    },
    getError: function (state) {
        return state.isError;
    },
    getAccessToken: function (state) {
        return state.context;
    },
};
// const accessToken =
//   'eyJhbGciOiJIUzI1NiJ9.oGA1KJw-AEhie_IXyomSnwYDZWp5HWfcetWluXzWcNt9h7gauOzNlJKLkSjR6pxROykM2O8X2rZp1Y3C_1khw-ZcHWSSG_W6faTLjNpyC0nCn8fCfoMdGOEk_jR8LAC9.jwWvYBZrChiRYJjuzSX5wEIf3EsTjVlf1kFeS2akwa4'
export var actions = {
    lineInit: function (_a) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, liff
                            .init({ liffId: reward.LIFF })
                            .then(function () { return __awaiter(_this, void 0, void 0, function () {
                            var accessToken;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!liff.isLoggedIn()) return [3 /*break*/, 2];
                                        return [4 /*yield*/, liff.getAccessToken()];
                                    case 1:
                                        accessToken = _a.sent();
                                        commit('setContext', accessToken);
                                        return [3 /*break*/, 3];
                                    case 2:
                                        liff.login();
                                        _a.label = 3;
                                    case 3: return [2 /*return*/, true];
                                }
                            });
                        }); })
                            .catch(function (error) {
                            commit('setError', error);
                            console.log("liff.init() failed: " + error);
                            return false;
                        })];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    },
    verifyLineId: function (_a) {
        var commit = _a.commit, state = _a.state;
        return __awaiter(this, void 0, void 0, function () {
            var response, token, response, verified, profileLine, psInfo, error_1, response;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(state.authenToken == null)) return [3 /*break*/, 2];
                        return [4 /*yield*/, authenToken()];
                    case 1:
                        response = _b.sent();
                        token = _.get(response, 'data.token_type') + ' ' + _.get(response, 'data.access_token');
                        commit('updateToken', token);
                        _b.label = 2;
                    case 2:
                        _b.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, verifyLineId(state.authenToken, { accessToken: state.context })];
                    case 3:
                        response = _b.sent();
                        verified = _.get(response, 'data.result');
                        profileLine = _.get(response, 'data.data.profileLine');
                        commit('updateProfile', profileLine);
                        // commit('setLoading', false)
                        if (verified) {
                            psInfo = _.get(response, 'data.data.psInfo', null);
                            commit('updateLineLoggedIn', true);
                            commit('updateInfomation', psInfo);
                            commit('setError', null);
                        }
                        else {
                            commit('updateLineLoggedIn', false);
                            commit('updateInfomation', null);
                        }
                        return [3 /*break*/, 5];
                    case 4:
                        error_1 = _b.sent();
                        response = error_1.response;
                        console.log('error', response.data.message);
                        // commit('setLoading', false)
                        commit('updateInfomation', null);
                        commit('updateLineLoggedIn', false);
                        throw error_1;
                    case 5: return [2 /*return*/];
                }
            });
        });
    },
    createChocoToken: function (_a, redirectPage) {
        var commit = _a.commit, state = _a.state;
        return __awaiter(this, void 0, void 0, function () {
            var response, result, redirectToken, error_2, response;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (state.authenToken == null) {
                            return [2 /*return*/];
                        }
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, createChocoToken({ accessToken: state.context, redirectPage: redirectPage }, state.authenToken)];
                    case 2:
                        response = _b.sent();
                        result = _.get(response, 'data.result');
                        // commit('setLoading', false)
                        if (result) {
                            redirectToken = _.get(response, 'data.data.redirectToken');
                            commit('updateChocoToken', redirectToken);
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        error_2 = _b.sent();
                        response = error_2.response;
                        console.log('error', response.data.message);
                        // commit('setLoading', false)
                        commit('setError', "Error: " + error_2 + " " + response.data.message + " " + JSON.stringify(context));
                        throw error_2;
                    case 4: return [2 /*return*/];
                }
            });
        });
    },
    updateLineLoggedIn: function (_a) {
        var commit = _a.commit;
        commit('updateLineLoggedIn', true);
    },
};
export var makeLineKa = function () { return ({
    namespaced: true,
    state: buildState(),
    getters: getters,
    mutations: mutations,
    actions: actions,
}); };
