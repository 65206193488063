export const interceptorRegister = (axiosInstance, eventBus, cookieHandler, emitError) => {
  autoUpdateTokenRegister(axiosInstance, cookieHandler)
  if (emitError) {
    apiErrorHandleRegister(axiosInstance, eventBus)
  }
  return axiosInstance
}

export const autoUpdateTokenRegister = (axiosInstance, cookieHandler) => {
  axiosInstance.interceptors.response.use(
    response => {
      autoUpdateTokenHandler(cookieHandler, response)
      return response
    },
    error => {
      autoUpdateTokenHandler(cookieHandler, error.response)
      return Promise.reject(error)
    },
  )
}

export const apiErrorHandleRegister = (axiosInstance, eventBus) => {
  axiosInstance.interceptors.response.use(null, error => {
    if (error.response && error.response.status < 500) {
      return Promise.reject(error)
    }
    eventBus.$emit('api-error', error.response)
    return Promise.reject(error)
  })
}

export const autoUpdateTokenHandler = (cookieHandler, response) => {
  cookieHandler.updateCsrfToken(response)
}
