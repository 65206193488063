import getBase from '@/api/base'
import _ from 'lodash'
import {normalizeCampaignQuery} from '@/utilities/campaign-cookie'
import {DealType, FlowType, getFlowTypeByDealType} from '@/utilities/deal-types'
import {formatNumberWithMaiximumDigits} from '@/utilities/util'

const {post} = getBase(true)

const getTotalMonths = (currentExperience, totalExperience) => {
  if (currentExperience === -1 && totalExperience === -1) {
    return null
  }
  const experience = currentExperience > totalExperience ? currentExperience : totalExperience
  return experience > 15 ? 9999 : experience * 12
}

const changeConsentFlag = data => {
  return data.map(v =>
    v.flag
      ? {
        ...v,
        flag: 'Y',
      }
      : {
        ...v,
        flag: 'N',
      },
  )
}

const getZipcode = data => {
  try {
    return JSON.parse(data).zipCode.toString()
  } catch (e) {
    return null
  }
}

const getProduct = product => {
  return {
    subProduct: _.get(product, 'subProduct'),
  }
}
const getPersonalInformation = (customerInformation, type) => {
  const defaultValue = {
    title: _.get(customerInformation, 'title'),
    thaiFirstName: _.get(customerInformation, 'thaiFirstName'),
    thaiLastName: _.get(customerInformation, 'thaiLastName'),
    dob: _.get(customerInformation, 'dob'),
    citizenId: _.get(customerInformation, 'citizenId'),
    email: _.get(customerInformation, 'email'),
    occupationType: _.get(customerInformation, 'occupation'),
    provinceDesc: _.get(customerInformation, 'province'),
    postalCode: getZipcode(_.get(customerInformation, 'postalCode')),
    bookingPeriod: getBookingPeriod(customerInformation, type),
    crossSaleConsent: _.get(customerInformation, 'consentFlag'),
    personalDataProtectionActConsent: _.get(customerInformation, 'personalDataProtectionActConsent', ''),
    partnerConsent: changeConsentFlag(_.get(customerInformation, 'partnerConsent')),
    enFirstName: _.get(customerInformation, 'enFirstName'),
    enLastName: _.get(customerInformation, 'enLastName'),
  }

  return defaultValue
}

const getBookingPeriod = (customerInformation, type) => {
  if (type === DealType.EXCLUSIVE) {
    return ''
  }
  return _.get(customerInformation, 'bookingPeriod')
}

const getFinancialInformation = financialInfo => {
  return {
    incomePerMonth: _.get(financialInfo, 'income'),
    monthsOfService: getTotalMonths(_.get(financialInfo, 'currentExperience'), _.get(financialInfo, 'totalExperience')),
  }
}

const getRiskQuestions = (riskQuestion, type) => {
  if (type === DealType.EXCLUSIVE) {
    return {}
  }
  return {
    applyForLoan: _.get(riskQuestion, 'applyForLoan'),
    hasVehicleLoan: _.get(riskQuestion, 'loanUsage.vehicle'),
    hasMotorCycleLoan: _.get(riskQuestion, 'loanUsage.motorcycle'),
    hasResidenceLoan: _.get(riskQuestion, 'loanUsage.resident'),
    hasPersonalLoan: _.get(riskQuestion, 'loanUsage.personalLoan'),
    hasOverDraft: _.get(riskQuestion, 'loanUsage.overDraft'),
    hasCreditCard: _.get(riskQuestion, 'loanUsage.creditCard'),
    overdue: _.get(riskQuestion, 'overdue') || false,
  }
}

const getLoanFinanceDetail = (type, creditLineTerm) => {
  if (type === DealType.EXCLUSIVE) {
    return {
      requireNcbChecking: creditLineTerm.ncbProcessFlag,
      loanFinanceDetail: {
        maxFinanceAmount: null,
        requestAmount: formatNumberWithMaiximumDigits(creditLineTerm.amount, 2),
        installmentTerms: creditLineTerm.term,
        installmentAmount: formatNumberWithMaiximumDigits(creditLineTerm.maxInstallmentAmountExcludedVat, 2),
        percentDown: creditLineTerm.minPercentDown,
      },
    }
  }
  return {}
}

const getCrossSaleCreditLineTerm = (productType, crossSalesPool) => {
  const crossSales = crossSalesPool.crossSalesPool.find(product => productType.subProduct === product.product)
  const creditLineTerms = _.get(crossSales, 'creditLineTerms', null)
  const rawCreditLineTerms = creditLineTerms ? JSON.stringify(creditLineTerms) : null
  return {crossSaleCreditLineTerm: rawCreditLineTerms}
}

const getExclusive = exclusive => {
  return {
    crossSalesPool: _.get(exclusive, 'crossSalesPool'),
  }
}

const appendFields = (obj, requestObject) => {
  for (const key in obj) {
    requestObject[key] = obj[key]
  }
}
const makeRequest = (state, query) => {
  const type = _.get(state, 'deal.selectedDeal.type', DealType.NORMAL)
  const customerType = getExclusive(state.exclusive)
  const product = getProduct(state.noDocument.product)
  const personalInfo = getPersonalInformation(state.customerInformation.customerInformation, type)
  const financialInfo = getFinancialInformation(state.noDocument.financialInfo)
  const crossSaleCreditLineTerm = getCrossSaleCreditLineTerm(product, customerType)
  const riskQuestion = getFlowTypeByDealType(type) === FlowType.RISKQ ? getRiskQuestions(state.noDocument.riskQuestion) : {}
  const customerSegment =
    customerType.crossSalesPool.length > 0
      ? {
        customerSegment: 'CRM',
      }
      : {
        customerSegment: 'NML',
      }
  const preApprovedFormula =
    type === DealType.EXCLUSIVE
      ? {
        preApprovedFormula: 'XSELL',
      }
      : {
        preApprovedFormula: 'RISKQ',
      }
  const loanFinanceDetail = getLoanFinanceDetail(type, state.deal.creditLineTerm)
  const platformChannel = {platformChannel: _.get(query, 'mobile', '') === 'true' ? 'GO' : 'WEB'}
  const subCampaign = {subCampaign: _.get(state, 'deal.selectedDeal.subCampaign', '')}
  const referenceId = {referenceId: decodeURIComponent(_.get(query, 'reference_id', ''))}

  const requestObject = {}
  appendFields(product, requestObject)
  appendFields(personalInfo, requestObject)
  appendFields(financialInfo, requestObject)
  appendFields(riskQuestion, requestObject)
  appendFields(subCampaign, requestObject)
  appendFields(customerSegment, requestObject)
  appendFields(preApprovedFormula, requestObject)
  appendFields(loanFinanceDetail, requestObject)
  appendFields(platformChannel, requestObject)
  appendFields(crossSaleCreditLineTerm, requestObject)
  appendFields(referenceId, requestObject)

  return requestObject
}

function attachCampaignData (requestObject, campaignData) {
  appendFields(campaignData, requestObject)
  return requestObject
}

export const buildSubmitApplicationData = ({state, query}) => {
  const normalizedQuery = normalizeCampaignQuery(query)
  return attachCampaignData(makeRequest(state, query), normalizedQuery)
}

export const submitLoanApplication = async ({state, query}) => {
  const requestData = buildSubmitApplicationData({
    state,
    query,
  })
  return post('/loan-application', requestData, {
    headers: {
      'Content-Type': undefined,
    },
    timeout: 60 * 1000,
  })
}

export const appendImage = (imageType, name, formData) => {
  imageType.forEach((img, index) => {
    formData.append(name, img, `${name}${index + 1}.jpeg`)
  })
}

const makeReUploadData = state => {
  const formData = new FormData()
  appendImage(state.document.idCardData, 'id', formData)
  appendImage(state.document.payslipData, 'payslip', formData)
  appendImage(state.document.otherData, 'other', formData)
  appendImage(state.document.employeeCardData, 'employeeCard', formData)
  appendImage(state.document.bankStatementData, 'bankStatements', formData)
  return formData
}

export const reUploadDocument = state =>
  post('/document', makeReUploadData(state), {
    headers: {
      'Content-Type': undefined,
    },
    timeout: 60 * 1000,
  })

export const archive = () => post('/loan-application/archive')
