// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import VueLazyload from 'vue-lazyload'
import App from './App'
import VueCookies from 'vue-cookies'
import VueAnalytics from 'vue-analytics'
import {makeRouter} from '@/router'
import {i18n} from './i18n'
import {makeStore} from './store'
import {globalGuardFactory} from '@/router/Guard'
import * as api from '@/api'
import Vuelidate from 'vuelidate'
import {VueReCaptcha} from 'vue-recaptcha-v3'
import VueMeta from 'vue-meta'
import VueGtag from 'vue-gtag'

Vue.config.productionTip = false

const {features = {}} = window || {}
const {captcha = {}} = window || {}
const {amplitude = {}} = window || {}
const {ga4 = {}} = window || {}
const store = makeStore()
const router = makeRouter({
  store,
  features,
  globalGuard: globalGuardFactory(store, api, features),
  amplitude,
})
router.beforeEach((to, from, next) => {
  document.body.classList.remove('modal-open')
  next()
})
Vue.use(VueMeta)

Vue.use(VueReCaptcha, {
  siteKey: captcha.SITE_KEY_V3,
  loaderOptions: {
    useRecaptchaNet: true,
    autoHideBadge: true,
  },
})

Vue.use(VueLazyload, {
  lazyComponent: true,
})
Vue.use(VueCookies)

Vue.use(Vuelidate)

Vue.use(VueAnalytics, {
  id: 'UA-124281505-1',
  router,
  checkDuplicatedScript: true,
  autoTracking: {
    prependBase: true,
    skipSamePath: true,
    exception: true,
    exceptionLogs: false,
  },
  batch: {
    enabled: true, // enable/disable
    amount: 2, // amount of events fired
    delay: 500, // delay in milliseconds
  },
  disabled: () => {
    return !!window.location.href.includes('reference_id')
  },
  debug: {
    enabled: false, // default value
    trace: false, // default value
    sendHitTask: true, // default value
  },
})

Vue.use(VueGtag, {
  config: {
    id: ga4.MEASUREMENT_ID,
  },
})

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  i18n,
  store,
  render: h => h(App),
})
