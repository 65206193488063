import * as routeConfig from '@/router/config'

const Blog1 = () => import('@/pages/blogs/Blog1' /* webpackChunkName: "line-oa-blog-1" */)
const Blog2 = () => import('@/pages/blogs/Blog2' /* webpackChunkName: "line-oa-blog-2" */)
const Blog3 = () => import('@/pages/blogs/Blog3' /* webpackChunkName: "line-oa-blog-3" */)
const Blog4 = () => import('@/pages/blogs/Blog4' /* webpackChunkName: "line-oa-blog-4" */)
const Blog5 = () => import('@/pages/blogs/Blog5' /* webpackChunkName: "line-oa-blog-5" */)
const BlankPage = () => import('@/pages/blogs/BlankPage')

export const makeRoute = features => {
  return [
    {
      path: '',
      name: 'contents',
      component: BlankPage,
      meta: {
        routeConfig: routeConfig.allowAllRouteConfig,
      },
    },
    {
      path: '/contents/how-to-check-the-limit-before-buying-a-car',
      name: 'Blog1',
      component: Blog1,
      meta: {
        routeConfig: routeConfig.allowAllRouteConfig,
        canonical: 'https://promptstart.krungsriauto.com/contents/how-to-check-the-limit-before-buying-a-car',
      },
    },
    {
      path: '/contents/buying-new-car-choose-right-price',
      name: 'Blog2',
      component: Blog2,
      meta: {
        routeConfig: routeConfig.allowAllRouteConfig,
        canonical: 'https://promptstart.krungsriauto.com/contents/buying-new-car-choose-right-price',
      },
    },
    {
      path: '/contents/5-tips-for-buying-a-car',
      name: 'Blog3',
      component: Blog3,
      meta: {
        routeConfig: routeConfig.allowAllRouteConfig,
        canonical: 'https://promptstart.krungsriauto.com/contents/5-tips-for-buying-a-car',
      },
    },
    {
      path: '/contents/tips-for-buy-ev-car',
      name: 'Blog4',
      component: Blog4,
      meta: {
        routeConfig: routeConfig.allowAllRouteConfig,
        canonical: 'https://promptstart.krungsriauto.com/contents/tips-for-buy-ev-car',
      },
    },
    {
      path: '/contents/inspect-used-car-before-buying',
      name: 'Blog5',
      component: Blog5,
      meta: {
        routeConfig: routeConfig.allowAllRouteConfig,
        canonical: 'https://promptstart.krungsriauto.com/contents/inspect-used-car-before-buying',
      },
    },
  ]
}
