var _a, _b, _c, _d;
export var ProductType;
(function (ProductType) {
    ProductType["NEW_CAR"] = "newCar";
    ProductType["USED_CAR"] = "usedCar";
    ProductType["BIG_BIKE"] = "bigBike";
    ProductType["MOTOR_CYCLE"] = "motorCycle";
})(ProductType || (ProductType = {}));
export var CampaignProductType;
(function (CampaignProductType) {
    CampaignProductType["CAMPAIGN_NEW_CAR"] = "NC";
    CampaignProductType["CAMPAIGN_USED_CAR"] = "UC";
    CampaignProductType["CAMPAIGN_BIG_BIKE"] = "BB";
    CampaignProductType["CAMPAIGN_MOTOR_CYCLE"] = "MC";
})(CampaignProductType || (CampaignProductType = {}));
var productNameMapper = (_a = {},
    _a[CampaignProductType.CAMPAIGN_NEW_CAR] = ProductType.NEW_CAR,
    _a[CampaignProductType.CAMPAIGN_USED_CAR] = ProductType.USED_CAR,
    _a[CampaignProductType.CAMPAIGN_BIG_BIKE] = ProductType.BIG_BIKE,
    _a[CampaignProductType.CAMPAIGN_MOTOR_CYCLE] = ProductType.MOTOR_CYCLE,
    _a);
export var getProductNameByCampaignProductName = function (campaignProductName) { return productNameMapper[campaignProductName]; };
export var productNames = [ProductType.NEW_CAR, ProductType.USED_CAR, ProductType.BIG_BIKE, ProductType.MOTOR_CYCLE];
export var productMinimumIncomeMapper = (_b = {},
    _b[ProductType.NEW_CAR] = 10000,
    _b[ProductType.USED_CAR] = 10000,
    _b[ProductType.BIG_BIKE] = 15000,
    _b[ProductType.MOTOR_CYCLE] = 9000,
    _b);
export var productThaiNameMapper = (_c = {},
    _c[ProductType.NEW_CAR] = 'กรุงศรี นิวคาร์ สินเชื่อรถใหม่',
    _c[ProductType.USED_CAR] = 'กรุงศรี ยูสคาร์ สินเชื่อรถเต้นท์',
    _c[ProductType.BIG_BIKE] = 'กรุงศรี บิ๊กไบค์',
    _c[ProductType.MOTOR_CYCLE] = 'กรุงศรี มอเตอร์ไซค์',
    _c);
export var productEngNameMapper = (_d = {},
    _d[ProductType.NEW_CAR] = 'New Car',
    _d[ProductType.USED_CAR] = 'Used Car',
    _d[ProductType.BIG_BIKE] = 'Big Bike',
    _d[ProductType.MOTOR_CYCLE] = 'Motorcycle',
    _d);
