import * as routeConfig from '@/router/config'
const Request = () => import('@/app/login/component/Request')

export const makeRoute = ({store, features = {}}) => {
  return [
    {
      path: '',
      name: 'login',
      redirect: {name: 'requestOtp'},
    },
    {
      path: 'requestOtp',
      name: 'requestOtp',
      component: Request,
      props: true,
      meta: {
        routeConfig: routeConfig.preLoginRouteConfig,
      },
      beforeEnter: (to, from, next) => {
        store.dispatch('reset', null, {root: true})
        if (!store.state.noDocument.login.isLoggedIn) {
          next()
        }
      },
    },
  ]
}
