import getBasePromptStart from './base-promptstart-services'

const {planet = {}} = window || {}
const api = getBasePromptStart()

export const requestToken = async () => {
  try {
    const clientId = planet.PS_SERVICES_CLIENT_ID
    const clientSecret = planet.PS_SERVICES_CLIENT_SECRET
    const authString = `${clientId}:${clientSecret}`
    const authBase64 = btoa(authString)
    const authHeader = `Basic ${authBase64}`
    const response = await api.post(
      '/ps-dld/token',
      {},
      {
        headers: {
          Authorization: authHeader,
        },
      },
    )

    if (response.status === 200) {
      return response.data.access_token
    } else {
      throw new Error('Failed to retrieve token')
    }
  } catch (error) {
    console.error('Error in requestToken:', error)
    throw error
  }
}

export const saveData = (phoneNumber, token, query) => {
  console.log('Promstart Service Query', query)
  api
    .post(
      '/ps-dld/requests',
      {
        mobile_no: phoneNumber,
        purchase_now: 'Y',
        channel: 'JET',
        utm_source: query.ori_source,
        utm_medium: query.ori_medium,
        utm_campaign: query.ori_campaign,
        referal_code: query.referral_code,
        product: query.campaign_product,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .catch(error => {
      console.error('Error in saveData:', error)
    })
}

export default {
  requestToken,
  saveData,
}
