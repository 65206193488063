export var mutations = {
    updateVersion: function (state, value) {
        state.version = value;
    },
    reset: function (state) {
        state.version = '';
    },
};
export var actions = {
    updateVersion: function (_a, value) {
        var commit = _a.commit;
        commit('updateVersion', value);
    },
    reset: function (_a) {
        var commit = _a.commit;
        commit('reset');
    },
};
export var buildState = function () { return ({
    version: '',
}); };
export var makeSaleSheet = function () { return ({
    namespaced: true,
    state: buildState(),
    actions: actions,
    mutations: mutations,
}); };
