import * as routeConfig from '@/router/config'
const DLDCampaign = () => import('@/pages/dld/Campaign' /* webpackChunkName: "dld" */)
const Dld2w23q1 = () => import('@/pages/dld/23q1Dld2w' /* webpackChunkName: "dldcampaign" */)
const MotorShowCampaign = () => import('@/pages/dld/Motorshow' /* webpackChunkName: "dldcampaign-motorshow" */)
const EvSgQ42024 = () => import('@/pages/dld/ev_sg_q42024' /* webpackChunkName: "dldcampaign-motorshow" */)
const Q42024CrmUsed = () => import('@/pages/dld/q42024_crm_used' /* webpackChunkName: "q42024_crm_used" */)
const Payonduedate = () => import('@/pages/dld/Payonduedate.vue' /* webpackChunkName: "Payonduedate" */)
const DldPt = () => import('@/pages/dld/dld_pt.vue' /* webpackChunkName: "dld_pt" */)
const Q42024ProspectDldUsed = () => import('@/pages/dld/prospectdld_used.vue' /* webpackChunkName: "prospectdld_used" */)
const NcToyotaGoodgrade = () => import('@/pages/dld/nc_toyota_goodgrade.vue' /* webpackChunkName: "prospectdld_used" */)
export const makeRoute = features => {
  return [
    {
      path: '',
      name: 'Dld',
      redirect: {
        name: 'DLDCampaign',
      },
    },
    {
      path: 'campaign',
      name: 'DLDCampaign',
      component: DLDCampaign,
      meta: {
        routeConfig: routeConfig.allowAllRouteConfig,
      },
    },
    {
      path: '23q1-dld2w',
      name: 'Dld2w23q1',
      component: Dld2w23q1,
      beforeEnter: (to, from, next) => {
        if (features.DLD_CAMPAIGN) {
          next()
        } else {
          next({
            name: 'pageNotFound',
          })
        }
      },
      meta: {
        routeConfig: routeConfig.allowAllRouteConfig,
      },
    },
    {
      path: '24q1-motorshow',
      name: 'MotorShowCampaign',
      component: MotorShowCampaign,
      meta: {
        routeConfig: routeConfig.allowAllRouteConfig,
      },
    },
    {
      path: 'q42024_used_payonduedate',
      name: 'Payonduedate',
      component: Payonduedate,
      meta: {
        routeConfig: routeConfig.allowAllRouteConfig,
      },
    },
    {
      path: 'ev_sg_q42024',
      name: 'EVSGQ42024',
      component: EvSgQ42024,
      meta: {
        routeConfig: routeConfig.allowAllRouteConfig,
      },
    },
    {
      path: 'q42024_crm_used',
      name: 'Q42024CrmUsed',
      component: Q42024CrmUsed,
      meta: {
        routeConfig: routeConfig.allowAllRouteConfig,
      },
    },
    {
      path: 'dldpt500_ncuc',
      name: 'DldPt',
      component: DldPt,
      meta: {
        routeConfig: routeConfig.allowAllRouteConfig,
      },
    },
    {
      path: 'q42024_prospectdld_used',
      name: 'Q42024ProspectDldUsed',
      component: Q42024ProspectDldUsed,
      meta: {
        routeConfig: routeConfig.allowAllRouteConfig,
      },
    },
    {
      path: 'nc_toyota_goodgrade',
      name: 'NcToyotaGoodgrade',
      component: NcToyotaGoodgrade,
      meta: {
        routeConfig: routeConfig.allowAllRouteConfig,
      },
    },
  ]
}
