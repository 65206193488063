import {
  submissionFailedRouteConfig,
  allowThirdPartyRouteConfigAllDevice,
  noDocPostThirdPartySubmissionRouteConfig,
  noDocPostThirdPartySubmissionRouteConfigAllDevice,
} from '@/router/config'

const EFormThankYou = () => import('@/components/pages/EForm/EFormThankYou')
const EFormUploadingPage = () => import('@/components/pages/EForm/EFormUploadingPage')
const SubmissionFailed = () => import('@/components/pages/common/SubmissionFailed')

const ThankYou = () => import('@/components/pages/EForm/Thankyou')

export const makeRoute = ({store, features = {}}) => {
  return [
    {
      path: 'eFormUploading',
      name: 'EFormUploadingPage',
      component: EFormUploadingPage,
      meta: {
        routeConfig: allowThirdPartyRouteConfigAllDevice,
      },
    },
    {
      path: 'eFormSubmissionFailed',
      name: 'EFormSubmissionFailed',
      component: SubmissionFailed,
      meta: {
        routeConfig: submissionFailedRouteConfig,
      },
    },
    {
      path: 'eFormThankYou',
      name: 'eFormThankYou',
      component: EFormThankYou,
      meta: {
        routeConfig: noDocPostThirdPartySubmissionRouteConfig,
      },
    },
    {
      path: 'eFormDesktopThankYou',
      name: 'eFormDesktopThankYou',
      component: ThankYou,
      meta: {
        routeConfig: noDocPostThirdPartySubmissionRouteConfigAllDevice,
      },
    },
  ]
}
