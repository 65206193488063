import {addHours} from 'date-fns'
import moment from 'moment'
import {extendMoment} from 'moment-range'

export const getHoursInBkk = localDate => {
  const bkkOffset = 7
  const localTimezoneOffset = localDate.getTimezoneOffset() / -60
  const localToBkkOffset = bkkOffset - localTimezoneOffset

  return addHours(localDate, localToBkkOffset).getHours()
}

export const thaiFormat = (datePattern, date) => {
  const localLocal = moment(new Date(date))
  localLocal.locale('th')
  const christianYear = localLocal.format('YYYY')
  const buddhishYear = (parseInt(christianYear) + 543).toString()
  return localLocal.format(datePattern.replace('YYYY', buddhishYear).replace('YY', buddhishYear.substring(2, 4))).replace(christianYear, buddhishYear)
}

export const checkPeriodTime = (startDate, endDate, checkDate = Date.now()) => {
  const momentRange = extendMoment(moment)
  const start = moment(startDate)
  const end = moment(endDate)
  const range = momentRange.range(start, end)
  return range.contains(moment(checkDate))
}
