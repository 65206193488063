import getBase from '@/api/base'

const {post} = getBase(true)

export const customerInfo = referenceId =>
  post(
    '/partner/customer',
    {
      referenceId,
    },
    {
      timeout: 1000 * 10,
    },
  )
