<i18n>
  th:
    message: ระบบเกิดการขัดข้อง <br> อีกสักครู่ กรุณาลองอีกครั้งครับ
    closeButton: ปิด
</i18n>

<template>
  <transition name="slide-fade">
    <div
      class="toast-container"
      v-if="isShowError"
    >
      <div class="toast-box">
        <div
          class="toast-message"
          v-html="$t('message')"
        />
        <button
          class="toast-button"
          @click="hide"
        >
          {{ $t('closeButton') }}
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import {eventBus} from '@/eventBus'

export default {
  name: 'ToastNotification',
  mounted () {
    eventBus.$on('api-error', payload => {
      this.show()
    })
  },
  data () {
    return {
      isShowError: false,
      timeout: 4000,
    }
  },
  methods: {
    show () {
      this.isShowError = true
      setTimeout(() => {
        this.hide()
      }, this.timeout)
    },
    hide () {
      this.isShowError = false
    },
  },
}
</script>

<style lang="scss" scoped>
.toast-container {
  position: fixed;
  top: 0;
  width: 100%;
  padding: 15px;
  z-index: 998;

  .toast-box {
    position: relative;
    display: grid;
    grid-template-columns: 70% 30%;
    padding: 10px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.32);
    background-color: rgba(0, 0, 0, 0.91);
    border: 1px solid #999999;
    border-radius: 8px;

    .toast-message {
      color: #ffffff;
    }
    .toast-button {
      font-size: 16px;
      color: #999999;
      background: inherit;
      border: 1px solid #999999;
      border-radius: 4px;
      width: 63px;
      height: 32px;
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.4s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
</style>
