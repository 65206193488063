var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { navigate } from '@/utilities/routing';
import _ from 'lodash';
export var buildState = function () {
    return {
        product: '',
        subChannel: '',
        personalInfo: {
            thaiFirstName: null,
            thaiLastName: null,
            email: null,
            postalCode: null,
            phoneNumber: '',
            crossSaleConsent: null,
            partnerConsent: [],
            title: null,
            citizenId: null,
            province: null,
        },
        captchaInfo: {
            version: '',
            token: '',
        },
    };
};
export var getters = {
    getProduct: function (state) {
        return state.product;
    },
    isProductSelected: function (state) {
        return state.product.length > 0;
    },
    isPartiallyValidMobileNumber: function (_a) {
        var personalInfo = _a.personalInfo;
        var mobileNumber = personalInfo.phoneNumber;
        var lengthNumber = _.get(personalInfo, 'mobileNumber.length', '');
        var isLengthWithinTen = lengthNumber <= 10;
        var isNumeric = /^[0-9]*$/.test(mobileNumber);
        var isEmptyOrStartsWithZero = /^0?$/.test(mobileNumber);
        var isStartsWithValidPrefix = /^0[689][0-9]*/.test(mobileNumber);
        return (isEmptyOrStartsWithZero || isStartsWithValidPrefix) && isLengthWithinTen && isNumeric;
    },
};
export var mutations = {
    updateProduct: function (state, value) {
        state.product = value;
    },
    updateSubChannel: function (state, value) {
        state.subChannel = value;
    },
    updatePersonalInfo: function (state, personalInfo) {
        state.personalInfo = personalInfo;
    },
    updateCaptcha: function (state, captchaInfo) {
        state.captchaInfo = captchaInfo;
    },
    reset: function (state) {
        state.product = '';
        state.subChannel = '';
        state.personalInfo = {
            thaiFirstName: null,
            thaiLastName: null,
            email: null,
            postalCode: null,
            phoneNumber: '',
            crossSaleConsent: null,
            partnerConsent: [],
            title: null,
            citizenId: null,
            province: null,
        };
        state.captchaInfo.version = '';
        state.captchaInfo.token = '';
    },
    saveInputData: function (state, _a) {
        var value = _a.value, id = _a.id;
        state.personalInfo[id] = value;
    },
};
export var actions = {
    updateProduct: function (_a, value) {
        var commit = _a.commit;
        commit('updateProduct', value);
    },
    updateCaptcha: function (_a, captchaInfo) {
        var commit = _a.commit;
        commit('updateCaptcha', captchaInfo);
    },
    updateSubChannel: function (_a, value) {
        var commit = _a.commit;
        commit('updateSubChannel', value);
    },
    saveInputData: function (_a, _b) {
        var commit = _a.commit;
        var value = _b.value, id = _b.id;
        commit('saveInputData', { value: value, id: id });
    },
    savePersonalInfoData: function (_a, personalInfo) {
        var commit = _a.commit;
        commit('updatePersonalInfo', personalInfo);
    },
    updatePersonalInfo: function (_a, _b) {
        var commit = _a.commit;
        var router = _b.router, personalInfo = _b.personalInfo, captchaInfo = _b.captchaInfo;
        commit('updatePersonalInfo', personalInfo);
        commit('updateCaptcha', captchaInfo || {});
        navigate(router, { name: 'EFormUploadingPage', params: { source: 'eform' } });
    },
    updateInfomationData: function (_a, _b) {
        var commit = _a.commit;
        var personalInfo = _b.personalInfo, captchaInfo = _b.captchaInfo;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_c) {
                commit('updatePersonalInfo', personalInfo);
                commit('updateCaptcha', captchaInfo || {});
                return [2 /*return*/];
            });
        });
    },
    submit: function (_a, _b) {
        var state = _a.state, commit = _a.commit;
        var api = _b.api, router = _b.router, query = _b.query;
        return __awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 5, , 6]);
                        response = void 0;
                        if (!(state.subChannel === 'NSL')) return [3 /*break*/, 2];
                        return [4 /*yield*/, api.submitEForm({ state: state, query: query })];
                    case 1:
                        response = _c.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, api.submitEFormThirdParty({
                            state: state,
                            query: query,
                            captchaInfo: state.captchaInfo,
                        })];
                    case 3:
                        response = _c.sent();
                        _c.label = 4;
                    case 4:
                        if (response.data.isBot) {
                            if (state.subChannel === 'RSQ') {
                                navigate(router, {
                                    name: 'RiskQuestion',
                                    params: {
                                        mobile: response.data.mobile,
                                        isBot: response.data.isBot,
                                    },
                                });
                            }
                            else if (state.subChannel === 'FIN') {
                                navigate(router, {
                                    name: 'FinancialInfo',
                                    params: {
                                        mobile: response.data.mobile,
                                        isBot: response.data.isBot,
                                    },
                                });
                            }
                            else if (state.subChannel === 'CRM') {
                                navigate(router, {
                                    name: 'EFormCRM',
                                    params: {
                                        mobile: response.data.mobile,
                                        isBot: response.data.isBot,
                                    },
                                });
                            }
                            else {
                                navigate(router, {
                                    name: 'EformStandard',
                                    params: {
                                        mobile: response.data.mobile,
                                        isBot: response.data.isBot,
                                    },
                                });
                            }
                        }
                        else {
                            navigate(router, {
                                name: 'eFormThankYou',
                                params: {
                                    mobile: response.data.mobile,
                                },
                            });
                        }
                        return [3 /*break*/, 6];
                    case 5:
                        e_1 = _c.sent();
                        if (e_1.response && e_1.response.status === 400) {
                            navigate(router, {
                                name: 'EFormSubmissionFailed',
                                params: { errorCategory: 'BadRequest' },
                            });
                        }
                        else {
                            navigate(router, {
                                name: 'ServiceNotAvailablePage',
                                params: { type: 'EForm' },
                            });
                        }
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    },
    submitDesktop: function (_a, _b) {
        var state = _a.state;
        var api = _b.api, query = _b.query;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, api.submitEFormThirdParty({
                            state: state,
                            query: query,
                            captchaInfo: state.captchaInfo,
                        })];
                    case 1: return [2 /*return*/, _c.sent()];
                }
            });
        });
    },
};
export default {
    namespaced: true,
    state: buildState(),
    getters: getters,
    mutations: mutations,
    actions: actions,
};
