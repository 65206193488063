import * as routeConfig from '@/router/config'
const Home = () => import('@/pages/home/Home' /* webpackChunkName: "home" */)
const RequestExclusiveOtp = () => import('@/pages/otp/RequestExclusiveOtp' /* webpackChunkName: "request-exclusive-otp" */)
const Otp = () => import('@/pages/otp/Otp' /* webpackChunkName: "otp" */)
const DealList = () => import('@/pages/best-deal/DealList' /* webpackChunkName: "deal-list-exclusive" */)
const ExclusiveDealDetail = () => import('@/pages/best-deal/ExclusiveDealDetail' /* webpackChunkName: "deal-detail-exclusive" */)
const SaleSheet = () => import('@/pages/sale-sheet/SaleSheet' /* webpackChunkName: "sale-sheet" */)
const PersonalExclusiveInfomation = () => import('@/pages/personal-info/PersonalInfo' /* webpackChunkName: "personal-exclusive-info" */)
const Summary = () => import('@/pages/summary/Summary' /* webpackChunkName: "summary" */)
const CRMPreApproval = () => import('@/pages/pre-approval/CRMPreApproval' /* webpackChunkName: "crm-pre-approval" */)

export const makeRoute = ({store, features = {}}) => {
  return [
    {
      path: '',
      name: 'crm',
      redirect: {
        name: 'productHighlight',
      },
    },
    {
      path: 'product-highlight',
      name: 'productHighlight',
      component: Home,
      beforeEnter: (to, from, next) => {
        if (from && from.name === 'error') {
          store.dispatch('noDocument/reset')
          store.dispatch('customerInformation/reset')
        }
        next()
      },
      meta: {
        routeConfig: routeConfig.preLoginRouteConfig,
      },
    },
    {
      path: 'request-otp',
      name: 'RequestExclusiveOtp',
      component: RequestExclusiveOtp,
      beforeEnter: (to, from, next) => {
        if (store.state.noDocument.login.isLoggedIn) {
          next({
            name: 'DealListExclusive',
          })
        }
        next()
      },
      meta: {
        routeConfig: routeConfig.preLoginRouteConfigNoDoc,
      },
    },
    {
      path: 'verify-otp',
      name: 'VerifyExclusiveOtp',
      component: Otp,
      meta: {
        routeConfig: routeConfig.validateCrmConfigMissingData,
      },
    },
    {
      path: 'deal-list',
      name: 'DealListExclusive',
      component: DealList,
      meta: {
        routeConfig: routeConfig.dealRouteConfig,
        keepScroll: true,
      },
      beforeEnter: (to, from, next) => {
        store.commit('EForm/reset')
        if (store.getters['exclusive/isExclusive']) {
          next()
        } else {
          next({
            name: 'DealList',
          })
        }
      },
    },
    {
      path: 'deal-detail/:campaignName',
      name: 'ExclusiveDealDetail',
      component: ExclusiveDealDetail,
      meta: {
        routeConfig: routeConfig.dealRouteConfig,
      },
    },
    {
      path: 'deal-detail/:campaignName/:subCampaign',
      name: 'SubExclusiveDealDetail',
      component: ExclusiveDealDetail,
      meta: {
        routeConfig: routeConfig.dealRouteConfig,
      },
    },
    {
      path: 'sale-sheet',
      name: 'SaleSheet',
      component: SaleSheet,
      meta: {
        routeConfig: routeConfig.validateRefreshConfigMissingData,
      },
    },
    {
      path: 'personal-information',
      name: 'PersonalExclusiveInfomation',
      component: PersonalExclusiveInfomation,
      meta: {
        routeConfig: routeConfig.validateRefreshConfigMissingData,
      },
    },
    {
      path: 'summary',
      name: 'SummaryPage',
      component: Summary,
      meta: {
        routeConfig: routeConfig.validateRefreshConfigMissingData,
      },
    },
    {
      path: 'submit-otp',
      name: 'CrmSubmitOtp',
      component: Otp,
      meta: {
        routeConfig: routeConfig.validateRefreshConfigMissingData,
      },
    },
    {
      path: 'result',
      name: 'ExclusivePreApproval',
      component: CRMPreApproval,
      meta: {
        routeConfig: routeConfig.crmPreApprovedRouteConfig,
      },
    },
  ]
}
