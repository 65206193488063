<template>
  <div class="out-of-service">
    <img :src="getImage(content.image)">
    <div class="title">
      {{ content.title }}
    </div>
    <div class="krungsri-auto-promptstart-text">
      <span>{{ content.krungsriAutoPromptstart }}</span>
      <span class="text">{{ content.message }}</span>
    </div>
    <div
      id="messageDateTime"
      class="message mb-24"
      v-html="content.messageDateTime"
    />
    <div
      id="actionButton"
      class="message"
    >
      {{ content.actionButton }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'OutOfService',
  data () {
    return {
      content: {
        title: 'ขออภัยครับ',
        krungsriAutoPromptstart: 'กรุงศรี ออโต้ พร้อมสตาร์ท',
        message: 'แจ้งปิดปรับปรุงระบบเพิื่อพัฒนาการให้บริการ',
        messageDateTime: 'ในวันที่ 24 ก.ย. 64 เวลา <strong>00.00 - 05.00</strong> น.',
        actionButton: 'โปรดกลับมาใหม่อีกครั้ง',
        image: 'UnderConstruction_Lage.svg',
      },
    }
  },
  methods: {
    getImage (image) {
      return require('@/assets/images/' + image)
    },
  },
}
</script>

<style scoped lang="scss">
@import '~styles/main';

.out-of-service {
  display: table;
  margin: 30px auto auto auto;
  text-align: center;
  color: #5a5a5a;

  img {
    height: 220px;
    width: 220px;
  }
  .title {
    font-size: 24px;
    font-weight: 700;
    line-height: 41px;
    margin-top: 24px;
    margin-bottom: 16px;
  }
  .krungsri-auto-promptstart-text {
    font-size: 22px;
    font-weight: 500;
    line-height: 37px;
    display: inline-grid;

    @media (min-width: 600px) {
      display: inline-block;
      font-size: 18px;
      line-height: 30px;
    }

    .text {
      font-weight: normal;
    }

    @media (max-width: 599px) {
      .text {
        font-size: 14px;
        line-height: 28px;
      }
    }
  }
  .message {
    font-size: 14px;
    line-height: 28px;

    @media (min-width: 600px) {
      font-size: 16px;
    }
  }
  .mb-24 {
    margin-bottom: 24px;
  }
}
</style>
