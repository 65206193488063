import getBase from '@/api/base'

const {post} = getBase(true)

export const requestOtp = phoneNumber =>
  post(
    '/otp/generate',
    {
      phoneNumber,
    },
    {
      timeout: 1000 * 10,
    },
  )

export const verifyOtp = (phoneNumber, token, referenceNo, channel, accessToken) =>
  post(
    '/otp/verify',
    {
      phoneNumber,
      token,
      referenceNo,
      channel,
      accessToken,
    },
    {
      timeout: 1000 * 10,
    },
  )

export const verifyExclusiveOtp = (phoneNumber, token, citizenId, referenceNo) =>
  post(
    '/otp/verify-exclusive',
    {
      phoneNumber,
      token,
      citizenId,
      referenceNo,
    },
    {
      timeout: 1000 * 10,
    },
  )

export const verifyUser = (phoneNumber, token, citizenId, referenceNo) =>
  post(
    '/otp/verify-user',
    {
      phoneNumber,
      token,
      citizenId,
      referenceNo,
    },
    {
      timeout: 1000 * 10,
    },
  )
