import * as routeConfig from '@/router/config'
import {partnerLoanStatusDispatcher} from '@/utilities/routing'
const Request = () => import('@/app/login/component/Request')
const Otp = () => import('@/pages/otp/Otp' /* webpackChunkName: "line-oa-otp" */)
const LineOAHomePage = () => import('@/pages/line-oa/LineOAHomePage' /* webpackChunkName: "line-oa-preapprove-page" */)
const LoanExpired = () => import('@/components/pages/common/LoanExpired' /* webpackChunkName: "line-oa-expired-page" */)
const LoanUtilize = () => import('@/components/pages/common/PartnerLoanUtilize' /* webpackChunkName: "line-oa-loan-utilize" */)

export const makeRoute = ({store, features}) => {
  return [
    {
      path: '',
      name: 'lineOA',
      redirect: {
        name: features.SPIKE_LINE_OA === true ? 'PartnerRequestOtp' : 'pageNotFound',
        query: {
          partner: 'line',
        },
      },
    },
    {
      path: 'loanExpired',
      name: 'PartnerLoanExpired',
      component: LoanExpired,
      meta: {
        routeConfig: routeConfig.allowAllRouteConfig,
      },
    },
    {
      path: 'loanUtilize',
      name: 'PartnerLoanUtilize',
      component: LoanUtilize,
      meta: {
        routeConfig: routeConfig.allowAllRouteConfig,
      },
    },
    {
      path: 'reject',
      name: 'PartnerReject',
      component: () => import(/* webpackChunkName: "line-ka-reject" */ '@/components/pages/common/Reject'),
      meta: {
        routeConfig: routeConfig.allowAllRouteConfig,
      },
    },
    {
      path: 'request-otp',
      name: 'PartnerRequestOtp',
      component: Request,
      props: true,
      meta: {
        routeConfig: routeConfig.preLoginRouteConfig,
      },
      beforeEnter: async (to, from, next) => {
        store.commit('lineka/setLoading', true)
        await store.dispatch('lineka/lineInit')
        await store.dispatch('lineka/verifyLineId')

        const result = store.state.lineka.login
        if (result) {
          const infomation = store.getters['lineka/getInfomation']
          const _next = partnerLoanStatusDispatcher(infomation.loanInformation)
          next(_next)
        }
        next()
      },
    },
    {
      path: 'login-otp',
      name: 'PartnerLoginOtp',
      component: Otp,
      props: true,
      meta: {
        routeConfig: routeConfig.noDocInputOtpRouteConfig,
      },
    },
    {
      path: 'pre-approval',
      name: 'PartnerPreApproval',
      component: LineOAHomePage,
      props: true,
      meta: {
        routeConfig: routeConfig.allowAllRouteConfig,
      },
      // beforeEnter: async (to, from, next) => {
      //   store.commit('lineka/setLoading', true)
      //   await store.dispatch('lineka/lineInit')
      //   await store.dispatch('lineka/verifyLineId')
      //   next()
      // },
    },
  ]
}
