<template>
  <div class="service-down">
    <img src="@/assets/images/service_down.jpg">
  </div>
</template>

<script>
export default {
  name: 'ServiceDown',
}
</script>

<style lang="scss" scoped>
.service-down {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #ffffff;
  text-align: center;

  img {
    width: auto;
    @media (max-width: 767px) {
      width: 100%;
    }
  }
}
</style>
