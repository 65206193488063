var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { navigate } from '@/utilities/routing';
import _ from 'lodash';
import { DealType } from '@/utilities/deal-types';
export var ProductType;
(function (ProductType) {
    ProductType["FOUR_WHEELER"] = "FOUR_WHEELER";
    ProductType["TWO_WHEELER"] = "TWO_WHEELER";
})(ProductType || (ProductType = {}));
export var VerificationStatus;
(function (VerificationStatus) {
    VerificationStatus["APPLIED"] = "APPLIED";
    VerificationStatus["NOT_APPLIED"] = "NOT_APPLIED";
    VerificationStatus["SUBMITTED"] = "SUBMITTED";
    VerificationStatus["PENDING"] = "PENDING";
    VerificationStatus["PRE_APPROVED"] = "PRE_APPROVED";
    VerificationStatus["REJECT"] = "REJECT";
    VerificationStatus["CANCEL"] = "CANCEL";
    VerificationStatus["HARD_CASE"] = "HARD_CASE";
    VerificationStatus["UTILIZE"] = "UTILIZE";
    VerificationStatus["EXPIRED"] = "EXPIRED";
    VerificationStatus["ARCHIVED"] = "ARCHIVED";
})(VerificationStatus || (VerificationStatus = {}));
export var GetBackIn;
(function (GetBackIn) {
    GetBackIn["APOLOGY"] = "APOLOGY";
    GetBackIn["THIRTY_MINS"] = "THIRTY_MINS";
    GetBackIn["MORNING"] = "MORNING";
    GetBackIn["END_OF_HOLIDAY"] = "END_OF_HOLIDAY";
})(GetBackIn || (GetBackIn = {}));
export var SubProduct;
(function (SubProduct) {
    SubProduct["NEWCAR"] = "NEWCAR";
    SubProduct["USEDCAR"] = "USEDCAR";
    SubProduct["BIGBIKE"] = "BIGBIKE";
    SubProduct["MOTORCYCLE"] = "MOTORCYCLE";
})(SubProduct || (SubProduct = {}));
export var buildState = function () { return ({
    loanApplication: null,
}); };
export var getters = {
    getFullName: function (state) {
        return state.loanApplication.firstName + " " + state.loanApplication.lastName;
    },
    isDetailsSubmitted: function (state) {
        return state.loanApplication !== null;
    },
    getMRDetails: function (state) {
        return _.get(state, 'loanApplication.marketRepresentative', null);
    },
    getApplicationStatus: function (state) {
        return _.get(state, 'loanApplication.status', '');
    },
    getSubProduct: function (state) {
        return _.get(state, 'loanApplication.subProduct', '');
    },
    getPreApprove: function (state) {
        return _.get(state, 'loanApplication.preApprove', null);
    },
    getCampaignName: function (state) {
        return _.get(state, 'loanApplication.campaignName', '');
    },
    getSubCampaign: function (state) {
        return _.get(state, 'loanApplication.subCampaign', '');
    },
    getLoanExpireDate: function (state) {
        return _.get(state, 'loanApplication.loanExpireDate', null);
    },
    getMobileNumber: function (state) {
        return _.get(state, 'loanApplication.mobileNumber', null);
    },
    getSubmittedAt: function (state) {
        return _.get(state, 'loanApplication.submittedAt', null);
    },
    getExclusiveFinancialDetail: function (state) {
        return {
            amount: _.get(state, 'loanApplication.requestAmount', null),
            percentDown: _.get(state, 'loanApplication.percentDown', null),
            term: _.get(state, 'loanApplication.installmentTerms', null),
            installment: _.get(state, 'loanApplication.installmentAmount', null),
        };
    },
    getCrossSaleCreditLineTerm: function (state) {
        return JSON.parse(_.get(state, 'loanApplication.crossSaleCreditLineTerm', null));
    },
    getChanelCode: function (state) {
        return _.get(state, 'loanApplication.chanelCode', null);
    },
};
export var actions = {
    submit: function (_a, _b) {
        var rootState = _a.rootState, commit = _a.commit;
        var api = _b.api, router = _b.router, query = _b.query;
        return __awaiter(this, void 0, void 0, function () {
            var response, loanApplication, e_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, api.submitLoanApplication({
                                state: rootState,
                                query: query,
                            })];
                    case 1:
                        response = _c.sent();
                        loanApplication = response.data;
                        commit('updateLoanApplication', loanApplication);
                        commit('CustomerDetailStore/updateUserTryingToSubmitData', false, {
                            root: true,
                        });
                        if (_.get(rootState, 'deal.selectedDeal.type', 'NORMAL') === DealType.EXCLUSIVE) {
                            navigate(router, { name: 'ExclusivePreApproval' });
                        }
                        else {
                            navigate(router, { name: 'thankYou' });
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _c.sent();
                        commit('CustomerDetailStore/updateUserTryingToSubmitData', false, {
                            root: true,
                        });
                        if (e_1.response && e_1.response.status === 400) {
                            navigate(router, {
                                name: 'SubmissionFailed',
                                params: { errorCategory: 'BadRequest' },
                            });
                        }
                        else {
                            navigate(router, { name: 'ServiceNotAvailablePage' });
                        }
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    },
    reUpload: function (_a, _b) {
        var rootState = _a.rootState, commit = _a.commit;
        var api = _b.api, router = _b.router;
        return __awaiter(this, void 0, void 0, function () {
            var response, loanApplication, e_2;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, api.reUploadDocument(rootState)];
                    case 1:
                        response = _c.sent();
                        loanApplication = response.data;
                        commit('updateLoanApplication', loanApplication);
                        commit('CustomerDetailStore/updateUserTryingToSubmitData', false, {
                            root: true,
                        });
                        navigate(router, { name: 'thankYou' });
                        return [3 /*break*/, 3];
                    case 2:
                        e_2 = _c.sent();
                        commit('CustomerDetailStore/updateUserTryingToSubmitData', false, {
                            root: true,
                        });
                        navigate(router, { name: 'ServiceNotAvailablePage' });
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    },
    archive: function (_a, _b) {
        var commit = _a.commit;
        var api = _b.api;
        return __awaiter(this, void 0, void 0, function () {
            var response, loanApplication, e_3;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, api.archive()];
                    case 1:
                        response = _c.sent();
                        loanApplication = response.data.loanApplication;
                        commit('updateLoanApplication', loanApplication);
                        return [3 /*break*/, 3];
                    case 2:
                        e_3 = _c.sent();
                        commit('updateLoanApplication', null);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    },
};
export var mutations = {
    updateLoanApplication: function (state, application) {
        state.loanApplication = application;
    },
    reset: function (state) {
        state.loanApplication = null;
    },
};
export default {
    namespaced: true,
    state: buildState(),
    actions: actions,
    mutations: mutations,
    getters: getters,
};
