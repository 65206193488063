var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
export var buildState = function () { return ({
    deals: undefined,
    selectedDeal: null,
    creditLineTerm: null,
    isB2bDeal: null,
}); };
export var mutations = {
    updateDeals: function (state, deals) {
        state.deals = deals;
    },
    updateSelectedDeal: function (state, deal) {
        state.selectedDeal = deal;
    },
    updateCreditLineTerm: function (state, term) {
        state.creditLineTerm = term;
    },
    updatedIsB2bDeal: function (state, value) {
        state.isB2bDeal = value;
    },
    reset: function (state) {
        state.deals = undefined;
        state.selectedDeal = null;
        state.creditLineTerm = null;
        state.isB2bDeal = null;
    },
};
export var actions = {
    refreshDealList: function (_a, _b) {
        var commit = _a.commit, state = _a.state;
        var api = _b.api;
        return __awaiter(this, void 0, void 0, function () {
            var response, error_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (state.deals != null) {
                            return [2 /*return*/];
                        }
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, api.dealList()
                            // commit('updateDeals', json)
                        ];
                    case 2:
                        response = _c.sent();
                        // commit('updateDeals', json)
                        commit('updateDeals', response.data);
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _c.sent();
                        console.log('Failed to retrive deal data');
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    },
    updateSelectedDeal: function (_a, selectedDeal) {
        var commit = _a.commit;
        commit('updateSelectedDeal', selectedDeal);
    },
    updateCreditLineTerm: function (_a, term) {
        var commit = _a.commit;
        commit('updateCreditLineTerm', term);
    },
    updatedIsB2bDeal: function (_a, value) {
        var commit = _a.commit;
        commit('updatedIsB2bDeal', value);
    },
};
export var getters = {
    dealList: function (state) {
        return state.deals ? state.deals : [];
    },
    dealDetail: function (state) { return function (campaignName) {
        var filteredDeals = getters.dealList(state).filter(function (item) { return item.campaignName === campaignName; });
        return filteredDeals.length > 0 ? filteredDeals[0] : undefined;
    }; },
    dealDetailBySubCampaign: function (state) { return function (campaignName, subCampaign) {
        var filteredDeals = getters.dealList(state).filter(function (item) { return item.campaignName === campaignName && item.subCampaign === subCampaign; });
        return filteredDeals.length > 0 ? filteredDeals[0] : undefined;
    }; },
    dealImage: function (state) { return function (imgSource) {
        return "/api/deal/image/" + imgSource;
    }; },
    getSelectedDeal: function (state) {
        return state.selectedDeal;
    },
    getCreditLineTerm: function (state) {
        return state.creditLineTerm;
    },
    getIsB2bDeal: function (state) {
        return state.isB2bDeal;
    },
};
export default {
    namespaced: true,
    state: buildState(),
    actions: actions,
    mutations: mutations,
    getters: getters,
};
