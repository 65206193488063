var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import _ from 'lodash';
export var buildState = function () {
    return {
        customerDetails: {
            title: '',
            thaiFirstName: '',
            thaiLastName: '',
            firstName: '',
            lastName: '',
            dob: '',
            citizenId: '',
            cardExpiry: '',
            postalCode: '',
        },
        userTryToSubmitData: false,
        canEnterToZipCodePage: false,
    };
};
export var getters = {
    getCustomerDetails: function (state) {
        return state.customerDetails;
    },
    isUserTryToSubmitData: function (state) {
        return state.userTryToSubmitData;
    },
    getPrefixTitle: function (state) {
        switch (state.customerDetails.title) {
            case '':
                return '';
            case 'นาย':
                return 'mr';
            case 'นาง':
                return 'mrs';
            case 'นางสาว':
                return 'miss';
            default:
                return 'other';
        }
    },
    canEnterToZipCodePage: function (state) {
        return state.canEnterToZipCodePage;
    },
};
export var mutations = {
    updateCustomerDetail: function (state, _a) {
        var key = _a.key, value = _a.value;
        state.customerDetails[key] = value;
    },
    updateCustomerDetails: function (state, customerDetails) {
        state.customerDetails = customerDetails;
        state.userTryToSubmitData = true;
    },
    updateUserTryingToSubmitData: function (state, status) {
        state.userTryToSubmitData = status;
    },
    reset: function (state) {
        var newState = buildState();
        _.keys(state).forEach(function (key) {
            state[key] = newState[key];
        });
    },
    allowUserToEnterZipCodePage: function (state, status) {
        state.canEnterToZipCodePage = status;
    },
};
export var actions = {
    updateCustomerDetail: function (_a, _b) {
        var commit = _a.commit;
        var key = _b.key, value = _b.value;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_c) {
                commit('updateCustomerDetail', { key: key, value: value });
                return [2 /*return*/];
            });
        });
    },
    updateCustomerDetails: function (_a, customerDetails) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                commit('updateCustomerDetails', customerDetails);
                return [2 /*return*/];
            });
        });
    },
    updateUserTryToSubmitData: function (_a, status) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                commit('updateUserTryingToSubmitData', status);
                return [2 /*return*/];
            });
        });
    },
    allowUserToEnterZipCodePage: function (_a, status) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                commit('allowUserToEnterZipCodePage', status);
                return [2 /*return*/];
            });
        });
    },
};
export default {
    namespaced: true,
    state: buildState(),
    actions: actions,
    mutations: mutations,
    getters: getters,
};
