export var buildState = function () { return ({
    applyForLoan: null,
    loanUsage: null,
    overdue: null,
}); };
export var getters = {
    isValid: function (_a) {
        var applyForLoan = _a.applyForLoan, loanUsage = _a.loanUsage, overdue = _a.overdue;
        var checkLoanUsage = loanUsage !== null ? Object.values(loanUsage).reduce(function (c, p) { return c || p; }, false) : false;
        return applyForLoan !== null && loanUsage !== null && (checkLoanUsage === false || (checkLoanUsage === true && overdue !== null));
    },
};
export var mutations = {
    updateApplyForLoan: function (state, value) {
        state.applyForLoan = value;
    },
    updateLoanUsage: function (state, value) {
        state.loanUsage = value;
        var checkLoanUsage = state.loanUsage !== null ? Object.values(state.loanUsage).reduce(function (c, p) { return c || p; }, false) : false;
        if (!checkLoanUsage) {
            state.overdue = null;
        }
    },
    updateOverdue: function (state, value) {
        state.overdue = value;
    },
    reset: function (state) {
        state.applyForLoan = null;
        state.loanUsage = null;
        state.overdue = null;
    },
};
export var actions = {
    updateApplyForLoan: function (_a, value) {
        var commit = _a.commit;
        commit('updateApplyForLoan', value);
    },
    updateLoanUsage: function (_a, value) {
        var commit = _a.commit;
        commit('updateLoanUsage', value);
    },
    updateOverdue: function (_a, value) {
        var commit = _a.commit;
        commit('updateOverdue', value);
    },
};
export var makeRiskQuestion = function () { return ({
    namespaced: true,
    state: buildState(),
    getters: getters,
    mutations: mutations,
    actions: actions,
}); };
