import getBase from '@/api/base'
import _ from 'lodash'

const {post} = getBase(true)

const changeConsentFlag = data => {
  return data.map(v => (v.flag ? {...v, flag: 'Y'} : {...v, flag: 'N'}))
}

const getZipcode = data => {
  try {
    return JSON.parse(data).zipCode.toString()
  } catch (e) {
    return null
  }
}

export const makeFormData = ({state, query}) => {
  const subChannel = _.get(state, 'subChannel')
  let postalCode = _.get(state, 'personalInfo.postalCode')
  let mobileNumberStoreKey = 'personalInfo.phoneNumber'
  let consentFlagStoreKey = 'personalInfo.crossSaleConsent'

  if (subChannel === 'RSQ' || subChannel === 'FIN') {
    postalCode = getZipcode(postalCode)
    mobileNumberStoreKey = 'personalInfo.mobileNumber'
    consentFlagStoreKey = 'personalInfo.consentFlag'
  }

  return {
    product: _.get(state, 'product'),
    citizenId: _.get(state, 'personalInfo.citizenId'),
    firstName: _.get(state, 'personalInfo.thaiFirstName'),
    lastName: _.get(state, 'personalInfo.thaiLastName'),
    email: _.get(state, 'personalInfo.email'),
    postalCode: postalCode,
    province: _.get(state, 'personalInfo.province'),
    crossSaleConsent: _.get(state, consentFlagStoreKey),
    partnerConsent: changeConsentFlag(_.get(state, 'personalInfo.partnerConsent', [])),
    title: _.get(state, 'personalInfo.title'),
    subChannel: subChannel,
    phoneNumber: _.get(state, mobileNumberStoreKey),
    applySource: _.get(query, 'apply_source'),
    applyMedium: _.get(query, 'apply_medium'),
    applyCampaign: _.get(query, 'apply_campaign'),
    applyReferralCode: _.get(query, 'apply_referral_code'),
    applyCampaignCode: _.get(query, 'apply_campaign_code'),
    applySubCampaign: _.get(query, 'apply_sub_campaign'),
    oriSource: _.get(query, 'ori_source'),
    oriMedium: _.get(query, 'ori_medium'),
    oriCampaign: _.get(query, 'ori_campaign'),
    oriReferralCode: _.get(query, 'referral_code'),
    oriCampaignCode: _.get(query, 'campaign_code'),
    oriSubCampaign: _.get(query, 'sub_campaign'),
  }
}

const callApi = ({state, query, endpoint, captchaInfo}) => {
  return post(endpoint, makeFormData({state, query}), {
    headers: {
      'Content-Type': undefined,
      version: _.get(captchaInfo, 'version', ''),
      token: _.get(captchaInfo, 'token', ''),
    },
    timeout: 60 * 1000,
  })
}

export const submitEForm = ({state, query}) => {
  const endpoint = '/e-form'
  return callApi({state, query, endpoint})
}

export const submitEFormThirdParty = ({state, query, captchaInfo}) => {
  const endpoint = '/e-form/third-party'
  return callApi({state, query, endpoint, captchaInfo})
}
