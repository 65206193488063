import axios from 'axios'
const {planet = {}} = window || {}

const getBasePromptStart = () => {
  const baseUrl = planet.PS_SERVICES_URL
  const path = planet.PS_SERVICES_PATH

  const tempBaseUrl = `https://${baseUrl}/${path}/v1`

  return axios.create({
    baseURL: tempBaseUrl,
  })
}

export default getBasePromptStart
