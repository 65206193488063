const CAMPAIGN_LIST = [
  'ori_source',
  'ori_medium',
  'ori_campaign',
  'sub_campaign',
  'referral_code',
  'campaign_code',
  'apply_source',
  'apply_medium',
  'apply_campaign',
  'apply_sub_campaign',
  'apply_referral_code',
  'apply_campaign_code',
]

const campaignMapper = {
  ori_source: 'oriSource',
  ori_medium: 'oriMedium',
  ori_campaign: 'oriCampaign',
  sub_campaign: 'oriSubCampaign',
  referral_code: 'oriReferralCode',
  campaign_code: 'oriCampaignCode',
  apply_source: 'applySource',
  apply_medium: 'applyMedium',
  apply_campaign: 'applyCampaign',
  apply_sub_campaign: 'applySubCampaign',
  apply_referral_code: 'applyReferralCode',
  apply_campaign_code: 'applyCampaignCode',
}

export function normalizeCampaignQuery (query) {
  const campaignList = CAMPAIGN_LIST
  return campaignList.reduce((obj, campaign) => {
    const campaignData = query[campaign]

    if (!campaignData) return obj

    const campaignKey = campaignMapper[campaign]
    return {
      ...obj,
      [campaignKey]: campaignData,
    }
  }, {})
}
