var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { LOAN_STATUS } from '@/utilities/Constants';
import _ from 'lodash';
export var decorateRoute = function (route) {
    var params = _.get(route, 'params', {});
    return __assign(__assign({}, route), { params: __assign(__assign({}, params), { actionNavigate: true }) });
};
export var loanStatusDispatcher = function (loanApplication) {
    if (loanApplication.apology) {
        return decorateRoute({ name: 'apologize' });
    }
    if (loanApplication.preApprovedFormula === 'XSELL') {
        switch (loanApplication.status) {
            case LOAN_STATUS.APPLIED:
            case LOAN_STATUS.PRE_APPROVED:
            case LOAN_STATUS.CALL_API_SUCCESS:
                return decorateRoute({ name: 'ExclusivePreApproval' });
        }
    }
    switch (loanApplication.status) {
        case LOAN_STATUS.PRE_APPROVED:
            return decorateRoute({ name: 'PreApproval' });
        case LOAN_STATUS.APPLIED:
        case LOAN_STATUS.SUBMITTED:
            return decorateRoute({ name: 'thankYou' });
        case LOAN_STATUS.PENDING:
            return decorateRoute({ name: 'ReUpload' });
        case LOAN_STATUS.WAITING_FOR_CONTACT:
            return decorateRoute({ name: 'WaitingKaContact' });
        case LOAN_STATUS.REJECT:
        case LOAN_STATUS.MANUAL_CANCEL:
        case LOAN_STATUS.AUTOMATIC_CANCEL:
        case LOAN_STATUS.CANCEL:
            return decorateRoute({ name: 'Reject' });
        case LOAN_STATUS.UTILIZE:
            return decorateRoute({ name: 'LoanUtilize' });
        case LOAN_STATUS.EXPIRED:
            return decorateRoute({ name: 'LoanExpired' });
        case LOAN_STATUS.HARD_CASE:
            return decorateRoute({ name: 'apologize' });
        case LOAN_STATUS.APPLICATION_EXPIRED:
            return decorateRoute({ name: 'ApplicationExpired' });
        default:
            return null;
    }
};
export var partnerLoanStatusDispatcher = function (loanApplication) {
    switch (loanApplication.status) {
        case LOAN_STATUS.PRE_APPROVED:
            return decorateRoute({
                name: 'PartnerPreApproval',
                query: {
                    partner: 'line',
                },
            });
        case LOAN_STATUS.REJECT:
        case LOAN_STATUS.CANCEL:
            return decorateRoute({
                name: 'PartnerReject',
                query: {
                    partner: 'line',
                },
            });
        case LOAN_STATUS.UTILIZE:
            return decorateRoute({
                name: 'PartnerLoanUtilize',
                query: {
                    partner: 'line',
                },
            });
        case LOAN_STATUS.EXPIRED:
            return decorateRoute({
                name: 'PartnerLoanExpired',
                query: {
                    partner: 'line',
                },
            });
        default:
            return decorateRoute({
                name: 'SelectProductV2',
                query: { partner: 'line' },
            });
    }
};
export function navigate(router, route) {
    router.push(decorateRoute(route));
}
