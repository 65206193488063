var _a;
export var DealType;
(function (DealType) {
    DealType["NORMAL"] = "NORMAL";
    DealType["DEALER"] = "DEALER";
    DealType["SPECIAL"] = "SPECIAL";
    DealType["EXCLUSIVE"] = "EXCLUSIVE";
    DealType["SPECIAL_CRM"] = "SPECIAL_CRM";
    DealType["B2B_API"] = "B2B_API";
})(DealType || (DealType = {}));
export var FlowType;
(function (FlowType) {
    FlowType["RISKQ"] = "RISKQ";
    FlowType["XSELL"] = "XSELL";
})(FlowType || (FlowType = {}));
var dealTypeNameMapper = (_a = {},
    _a[DealType.NORMAL] = FlowType.RISKQ,
    _a[DealType.DEALER] = FlowType.RISKQ,
    _a[DealType.SPECIAL] = FlowType.RISKQ,
    _a[DealType.SPECIAL_CRM] = FlowType.RISKQ,
    _a[DealType.B2B_API] = FlowType.RISKQ,
    _a[DealType.EXCLUSIVE] = FlowType.XSELL,
    _a);
export var getFlowTypeByDealType = function (dealType) { return dealTypeNameMapper[dealType]; };
