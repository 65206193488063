<template>
  <div
    class="text-middle"
    v-if="isTextMiddle"
  >
    <p class="text-title">
      <slot name="title" />
    </p>

    <p class="text-message">
      <slot name="message" />
    </p>
  </div>

  <div
    v-else
    :class="styleType"
  />
</template>
<script>
export default {
  name: 'Divider',
  props: {
    isTextMiddle: {
      type: Boolean,
      default: false,
    },
    styleType: {
      type: String,
      default: 'divider',
      require: false,
    },
  },
}
</script>
<style scoped lang="scss">
@import '~@/assets/styles/variable';

.divider {
  border-bottom: 2px solid $color-grey-light;
  margin-bottom: 30px;
  &::before {
    display: block;
    content: '';
    height: 30px;
  }
}
.divider-light {
  @extend .divider;
  margin: 0px;
  border-bottom: 1px solid hsla(0, 0%, 94%, 1);

  &::before {
    height: 0px;
  }
}
.text-middle {
  p {
    font-size: 14px;
    font-weight: normal;
    text-align: center;
    color: #5a5a5a;
  }

  p.text-title {
    overflow: hidden;
    margin-bottom: 15px;

    &:before,
    &:after {
      background-color: #efefef;
      content: '';
      display: inline-block;
      height: 1px;
      position: relative;
      vertical-align: middle;
      width: 50%;
    }
    &:before {
      right: 0.5em;
      margin-left: -50%;
    }

    &:after {
      left: 0.5em;
      margin-right: -50%;
    }
  }
}
</style>
