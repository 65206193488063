const cookieHandler = document => {
  const updateCsrfToken = response => {
    const nowDate = new Date()
    const expires = 'expires=' + getExpiryDateUTC(nowDate, 1)
    document.cookie = buildCsrfTokenCookie(
      'XSRF-TOKEN',
      response.headers['x-csrf-token'],
      expires,
    )
  }

  const getExpiryDateUTC = (nowDate, numberOfDaysToExpire) => {
    nowDate.setTime(
      nowDate.getTime() + numberOfDaysToExpire * 24 * 60 * 60 * 1000,
    )
    return nowDate.toUTCString()
  }

  const buildCsrfTokenCookie = (cookieName, cookieValue, expiredDate) => {
    return cookieName + '=' + cookieValue + ';' + expiredDate + ';path=/'
  }

  return {
    updateCsrfToken,
    getExpiryDateUTC,
    buildCsrfTokenCookie,
  }
}

export default cookieHandler
