import axios from 'axios'
const {reward = {}} = window || {}

const headerAuthen = {
  'Content-type': 'application/x-www-form-urlencoded',
}

const bodyAuthen = new URLSearchParams({
  scope: reward.TOKEN_SCOPE + '/rd-callback',
  client_id: reward.TOKEN_CLIENT_ID,
  client_secret: reward.TOKEN_CLIENT_SECRET,
})

export const authenToken = async () => {
  const response = await axios.post(`https://${reward.RD_SERVICE_ENDPOINT}/${reward.TOKEN_URL}/planet`, bodyAuthen, {headers: headerAuthen})
  return response
}

export const verifyLineId = async (token, body) => {
  const headers = {
    Authorization: token,
    'Content-type': 'application/json',
  }
  const response = await axios.post(`https://${reward.RD_SERVICE_ENDPOINT}/${reward.RD_SERVICE_URL}/v1/verify`, body, {headers})
  return response
}

export const createChocoToken = async (data, token) => {
  const headers = {
    Authorization: token,
    'Content-type': 'application/json',
  }
  const response = await axios.post(`https://${reward.RD_SERVICE_ENDPOINT}/${reward.RD_SERVICE_URL}/v1/create-choco-token`, data, {headers})
  return response
}
