var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[(_vm.isServiceDown)?[_c('ServiceDown')]:[(_vm.lineLoading)?_c('div',{staticClass:"loading"},[_c('div',{staticClass:"wrap-item"},[_c('img',{staticClass:"loading-cloud",attrs:{"src":require("@/assets/images/line-ka/loading-cloud.png"),"alt":"loading"}}),_vm._v(" "),_c('span',{staticClass:"check"},[_vm._v("กำลังตรวจสอบข้อมูล")]),_vm._v(" "),_c('span',[_vm._v("วงเงินพร้อมสตาร์ท")])])]):_vm._e(),_vm._v(" "),(_vm.isOverlaySpecialDay)?_c('Overlay',[_c('ImageSvg')],1):_vm._e(),_vm._v(" "),(_vm.isTemplateFullScreenWithOutHeaderAndFooter)?[_c('router-view')]:[_c('ToastNotification'),_vm._v(" "),_c('Header',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowHeaderFooter),expression:"isShowHeaderFooter"}],attrs:{"is-error-page":_vm.isError}}),_vm._v(" "),(!_vm.isOutOfService)?[(_vm.isNoDoc)?[_c('div',{staticClass:"home-wrapper",class:[_vm.getWrapperDesktopClass(), {'home-wrapper-clear': _vm.getQueryMobile, 'warpper-with-blogs': _vm.isShowBlogs, 'merge-journey': _vm.isMergeJourney}]},[_c('router-view')],1)]:[_c('div',{class:{
              'pre-wrapper': !_vm.isDesktopLanding && !_vm.isDealList && !_vm.isEFormDesktop && !_vm.isFaq && !_vm.isDldxPs,
              'pre-wrapper-deallist': _vm.isDealList,
              'merge-journey': _vm.isMergeJourney,
            }},[_c('div',{class:{
                wrapper:
                  !_vm.isHome && !_vm.isDesktopLanding && !_vm.isDealList && !_vm.isEFormDesktop && !_vm.isFinancialInfo && !_vm.isRiskQuestion && !_vm.isOtp && !_vm.isPreApproved && !_vm.isSaleSheet && !_vm.isDldxPs,
                faq: _vm.isFaq,
              }},[_c('router-view')],1)])]]:[_c('div',{staticStyle:{"margin-bottom":"56px"}},[_c('OutOfService')],1)],_vm._v(" "),(!_vm.isError)?_c('Footer',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowHeaderFooter),expression:"isShowHeaderFooter"}],attrs:{"collapse-active":_vm.getRoutePath}}):_vm._e()]]],2)}
var staticRenderFns = []

export { render, staticRenderFns }