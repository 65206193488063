import { productMinimumIncomeMapper } from '@/utilities/product-types';
export var buildState = function () { return ({
    income: null,
    currentExperience: -1,
    totalExperience: -1,
}); };
export var getters = {
    isValidIncome: function (_a, getters, rootState) {
        var income = _a.income;
        var selectedProduct = rootState.noDocument.product.subProduct;
        var minIncome = productMinimumIncomeMapper[selectedProduct];
        return {
            valid: income !== null && income.toString() !== '' ? income >= minIncome : true,
            minIncome: minIncome,
        };
    },
    isValidCurrentExperience: function (state) {
        return state.currentExperience != -1 && state.currentExperience != null;
    },
    isValidTotalExperience: function (state) {
        return state.totalExperience != -1 && state.totalExperience != 0 && state.totalExperience != null;
    },
};
export var mutations = {
    update: function (state, _a) {
        var key = _a.key, value = _a.value;
        state[key] = value;
    },
    reset: function (state) {
        state.income = null;
        state.currentExperience = -1;
        state.totalExperience = -1;
    },
};
export var actions = {
    updateIncome: function (_a, income) {
        var commit = _a.commit;
        commit('update', { key: 'income', value: income });
    },
    updateCurrentExperience: function (_a, numCurrentYear) {
        var commit = _a.commit;
        commit('update', { key: 'currentExperience', value: numCurrentYear });
        if (numCurrentYear > 0) {
            commit('update', { key: 'totalExperience', value: -1 });
        }
    },
    updateTotalExperience: function (_a, numTotalYear) {
        var commit = _a.commit;
        commit('update', { key: 'totalExperience', value: numTotalYear });
    },
};
export var makeFinancialInfo = function () { return ({
    namespaced: true,
    state: buildState(),
    getters: getters,
    mutations: mutations,
    actions: actions,
}); };
