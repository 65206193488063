import axios from 'axios'
import {interceptorRegister} from './factoryV2'
import cookieHandler from './cookieHandler'
import {eventBus} from '@/eventBus'

const config = {
  baseURL: '/api',
  xsrfHeaderName: 'X-CSRF-TOKEN',
}

const getBase = emitError => {
  const axiosInstance = axios.create(config)

  return interceptorRegister(
    axiosInstance,
    eventBus,
    cookieHandler(document),
    emitError,
  )
}

export default getBase
