export const headerLinks = [
  {display: 'ขอสินเชื่อ', ref: 'https://www.krungsriauto.com/auto/eform.html'},
  {display: 'กรุงศรี ออโต้ พร้อมสตาร์ท', ref: '/'},
  {
    display: 'ผลิตภัณฑ์สินเชื่อ',
    ref: 'https://www.krungsriauto.com/auto/Products.html',
  },
  {
    display: 'ผลิตภัณฑ์ประกันภัย',
    ref: 'https://www.krungsriauto.com/auto/KrungsriAuto-Broker.html',
  },
  {
    display: 'รถพร้อมประมูล',
    ref: 'https://www.krungsriauto.com/auto/Auction-Calendar.html',
  },
  {
    display: 'โปรโมชัน',
    ref: 'https://www.krungsriauto.com/auto/Promotion.html',
  },
  {
    display: 'ข่าวสารและกิจกรรม',
    ref: 'https://www.krungsriauto.com/auto/news-and-activities.html',
  },
  {
    display: 'เกี่ยวกับเรา',
    ref: 'https://www.krungsriauto.com/auto/About-Us/Krungsri-Auto.html',
  },
  {
    display: 'สมัครงาน',
    ref: 'https://www.krungsriauto.com/auto/Careers/JoinWithUs.html',
  },
  {
    display: 'ติดต่อเรา',
    ref: 'https://www.krungsriauto.com/auto/Contact-Us/address-and-map.html',
  },
  {
    display: 'กรุงศรี ออโต้ ออนไลน์',
    ref: 'https://onlineservices.krungsriauto.com/home/#/login',
  },
]

export const footerLinks = {
  main: [
    {
      display: 'กรุงศรี ออโต้',
      ref: 'https://www.krungsriauto.com/',
    },
    {
      display: 'โปรโมชัน',
      ref: 'https://www.krungsriauto.com/auto/Promotion.html',
    },
    {
      display: 'ขอสินเชื่อ',
      ref: 'https://www.krungsriauto.com/auto/eform.html',
    },
    {display: 'กรุงศรี ออโต้ พร้อมสตาร์ท', ref: '/'},
    {
      display: 'คำนวณสินเชื่อรถยนต์',
      ref: 'https://krungsrimarket.cjdataservice.com/newcar',
    },
  ],
  interestingStuff: [
    {
      display: 'ภาพยนตร์โฆษณา',
      ref: 'https://www.krungsriauto.com/auto/Ads-Video.html',
    },
    {
      display: 'นิตยสาร กรุงศรี ออโต้',
      ref: 'https://www.krungsriauto.com/auto/Krungsri-Auto-Magazine.html',
    },
    {
      display: 'ข่าวสารและกิจกรรม',
      ref: 'https://www.krungsriauto.com/auto/news-and-activities.html',
    },
  ],
  products: [
    {
      display: 'กรุงศรี รถบ้าน จัดไว จ่ายไว',
      ref: 'https://www.krungsriauto.com/auto/Products/RodBaanJudWaiJaiWai.html',
    },
    {
      display: 'กรุงศรี นิว คาร์',
      ref: 'https://www.krungsriauto.com/auto/Products/Krungsri-New-Car.html',
    },

    {
      display: 'กรุงศรี คาร์ ฟอร์ แคช',
      ref: 'https://www.krungsriauto.com/auto/Products/Krungsri-Car4Cash.html',
    },
    {
      display: 'กรุงศรี ยูสด์ คาร์',
      ref: 'https://www.krungsriauto.com/auto/Products/Krungsri-Used-Car.html',
    },
    {
      display: 'กรุงศรี รถบ้าน',
      ref: 'https://www.krungsriauto.com/auto/Products/Krungsri-Rod-Baan.html',
    },
    {
      display: 'กรุงศรี ทรัค',
      ref: 'https://www.krungsriauto.com/auto/Products/%E0%B8%AA%E0%B8%99%E0%B9%80%E0%B8%8A%E0%B8%AD-%E0%B8%97%E0%B8%A3%E0%B8%84.html',
    },
    {
      display: 'กรุงศรี บิ๊ก ไบค์ / กรุงศรี มอเตอร์ ไซค์',
      ref: 'https://www.krungsriauto.com/auto/Products/Krungsri-Big-Bike.html',
    },
    {
      display: 'กรุงศรี อินเวนทอรี่ ไฟแนนซ์',
      ref: 'https://www.krungsriauto.com/auto/Products/Krungsri-Inventory-Finance.html',
    },
  ],
  contactUs: [
    {
      display: 'ที่ตั้งสาขา',
      ref: 'https://www.krungsriauto.com/auto/location.html',
    },
    {
      display: 'สอบถามข้อมูลและบริการอื่นๆ',
      ref: 'https://www.krungsriauto.com/auto/Contact-Us/Inquiries-and-other-services.html',
    },
    {
      display: 'คำถามที่พบบ่อย',
      ref: 'https://www.krungsriauto.com/auto/QA.html',
    },
    {
      display: 'สมัครงานกับ กรุงศรี ออโต้',
      ref: 'https://www.krungsriauto.com/auto/Careers/JoinWithUs.html',
    },
  ],
  auction: [
    {
      display: 'เกี่ยวกับการประมูล',
      ref: 'https://www.krungsriauto.com/auto/auction-calendar-content/about-auction-calendar.html',
    },
    {
      display: 'ขั้นตอนการประมูล',
      ref: 'https://www.krungsriauto.com/auto/auction-calendar-content/procedure-auction.html',
    },
    {
      display: 'สินเชื่อรถประมูล',
      ref: 'https://www.krungsriauto.com/auto/auction-calendar-content/car-loan-auction.html',
    },
    {
      display: 'ปฏิทินการประมูล',
      ref:
        'https://www.krungsriauto.com/auto/auction-calendar-content/%E0%B8%9B%E0%B8%8F%E0%B8%97%E0%B8%99%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%9B%E0%B8%A3%E0%B8%B0%E0%B8%A1%E0%B8%A5.html',
    },
  ],
  aboutUs: [
    {
      display: 'กรุงศรี ออโต้',
      ref: 'https://www.krungsriauto.com/auto/About-Us/Krungsri-Auto.html',
    },
    {
      display: 'ประเภทสินเชื่อยานยนต์ที่ให้บริการ',
      ref: 'https://www.krungsriauto.com/auto/About-Us/typeof.html',
    },
    {
      display: 'ช่องทางการให้บริการ',
      ref: 'https://www.krungsriauto.com/auto/About-Us/Service-Channel.html',
    },
    {
      display: 'รางวัลและการยอมรับ',
      ref: 'https://www.krungsriauto.com/auto/About-Us/Awards.html',
    },
    {
      display: 'นโยบายการให้สินเชื่ออย่างรับผิดชอบ',
      ref: 'https://www.krungsriauto.com/auto/About-Us/Responsible-credit-policy.html',
    },
    {
      display: 'ข่าวสารและกิจกรรม',
      ref: 'https://www.krungsriauto.com/auto/news-and-activities.html',
    },
    {
      display: 'การเปิดเผยข้อมูล',
      ref: 'https://www.krungsriauto.com/auto/About-Us/Disclosure.html',
    },
    {
      display: 'นโยบายคุกกี้',
      ref: 'https://www.krungsriauto.com/auto/About-Us/cookie-policy.html',
    },
    {
      display: 'ประกาศการคุ้มครองข้อมูลส่วนบุคคล',
      ref: 'https://www.krungsriauto.com/auto/About-Us/Privacy_Notice.html',
    },
  ],
  others: [
    {
      display: 'www.car4cash.com',
      ref: 'https://www.krungsriauto.com/auto/other/www-car4cash-com.html',
    },
    {
      display: 'www.krungsrimarket.com',
      ref: 'https://www.krungsriauto.com/auto/other/www-krungsrimarket-com.html',
    },
    {
      display: 'www.krungsribigbike.com',
      ref: 'https://www.krungsriauto.com/auto/other/www-krungsribigbike-com.html',
    },
  ],
}
