<template>
  <footer
    :class="{collapse: collapse}"
    v-if="!isError"
  >
    <div class="footer-link-grid">
      <div class="footer-links-section">
        <div
          :key="link.display"
          v-for="link in footerLinks.main"
        >
          <a
            v-if="link.ref !== '/'"
            :href="link.ref"
          >{{ link.display }}</a>
          <a
            v-else
            @click="onClickToHomePage()"
          >{{ link.display }}</a>
        </div>
        <div
          class="bold"
          :class="{'mt-16': isMergeJourney}"
        >
          เรื่องที่น่าสนใจ
        </div>
        <div
          :key="link.display"
          v-for="link in footerLinks.interestingStuff"
        >
          <a :href="link.ref">{{ link.display }}</a>
        </div>
      </div>
      <div class="footer-links-section">
        <div class="bold">
          ผลิตภัณฑ์
        </div>
        <div
          :key="link.display"
          v-for="link in footerLinks.products"
        >
          <a :href="link.ref">{{ link.display }}</a>
        </div>
      </div>
      <div class="footer-links-section">
        <div class="bold">
          ติดต่อเรา
        </div>
        <div
          :key="link.display"
          v-for="link in footerLinks.contactUs"
        >
          <a :href="link.ref">{{ link.display }}</a>
        </div>
        <div
          class="bold"
          :class="{'mt-16': isMergeJourney}"
        >
          รถพร้อมประมูล
        </div>
        <div
          :key="link.display"
          v-for="link in footerLinks.auction"
        >
          <a :href="link.ref">{{ link.display }}</a>
        </div>
      </div>
      <div class="footer-links-section">
        <div class="bold">
          เกี่ยวกับเรา
        </div>
        <div
          :key="link.display"
          v-for="link in footerLinks.aboutUs"
        >
          <a :href="link.ref">{{ link.display }}</a>
        </div>
        <div
          class="bold"
          :class="{'mt-16': isMergeJourney}"
        >
          อื่นๆ
        </div>
        <div
          :key="link.display"
          v-for="link in footerLinks.others"
        >
          <a :href="link.ref">{{ link.display }}</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import {footerLinks} from './HeaderFooterLinks'

export default {
  name: 'FooterLinks',
  props: {
    collapse: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    onClickToHomePage () {
      if (this.$route.path !== '/') {
        this.$router.push({
          path: '/',
          query: this.$route.query,
        })
      }
    },
  },
  computed: {
    isError () {
      return this.$route.name === 'pageNotFound'
    },
    isMergeJourney () {
      return window.features.MERGE_JOURNEY && this.$route.name === 'home' && this.$route.path === '/'
    },
  },
  data: () => {
    return {
      footerLinks: footerLinks,
    }
  },
}
</script>

<style scoped lang="scss">
@import '~styles/main';

footer {
  z-index: 1;

  &.collapse {
    opacity: 0;
    position: absolute;
    top: -1000px;
    max-height: 0;
    visibility: hidden;
  }

  .footer-link-grid {
    display: grid;
    grid: auto / 50% 50%;
    box-sizing: border-box;
    padding: 40px 8px 40px;
    .footer-links-section {
      text-align: left;
      .bold {
        color: $krungsri-footer-heading;
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 3px;
      }
      div {
        padding: 5px 0 5px 0;
      }
      a {
        font-size: 14px;
        color: $krungsri-secondary;
        active {
          text-decoration: underline;
        }

        @media (min-width: 1025px) {
          font-size: 13px;
        }
      }
    }

    @media (min-width: 1025px) {
      grid: auto / 25% 25% 25% 25%;
      font-size: 13px;
    }
  }

  background-color: $krungsri-footer-background;
  color: $krungsri-secondary;
  padding-bottom: 15px;
  text-align: center;
}
.mt-16 {
  margin-top: 16px;
  margin-bottom: 0 !important;
}
</style>
