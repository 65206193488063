import { makeFinancialInfo, buildState as buildFinancialInfoState } from '@/store/no-document/store-module/store-financial-info';
import { makeRiskQuestion, buildState as buildRiskQuestionState } from '@/store/no-document/store-module/store-risk-question';
import { makeLogin, buildState as buildLoginState } from '@/store/no-document/store-module/store-login';
import { makeProduct, buildState as buildProductState } from '@/store/no-document/store-module/store-product';
export var buildState = function () { return ({
    financialInfo: buildFinancialInfoState(),
    riskQuestion: buildRiskQuestionState(),
    login: buildLoginState(),
    product: buildProductState(),
}); };
export var actions = {
    reset: function (_a) {
        var commit = _a.commit;
        commit('financialInfo/reset');
        commit('riskQuestion/reset');
        commit('product/reset');
    },
};
export var makeNoDocument = function () { return ({
    namespaced: true,
    modules: {
        financialInfo: makeFinancialInfo(),
        riskQuestion: makeRiskQuestion(),
        login: makeLogin(),
        product: makeProduct(),
    },
    actions: actions,
}); };
