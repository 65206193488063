import Vue from 'vue';
import Vuex from 'vuex';
import { makeLogin, buildState as buildLoginState } from '@/app/login/store-module';
import { makeLineKa, buildState as buildLineKaState } from '@/app/line-ka/store-module';
import CustomerDetailStore, { buildState as buildCustomerDetailState } from '@/components/pages/CustomerDetail/store/CustomerDetailStore';
import LoanApplication, { buildState as buildLoanApplicationState } from '@/app/loan-application/store-module';
import EForm, { buildState as buildEFormState } from '@/components/pages/EForm/store/e-form';
import _ from 'lodash';
import deal, { buildState as buildDealState } from '@/app/deal/store-module';
import { makeCustomerInformation, buildState as buildCustomerInformationState } from '@/store/store-customer-information';
import { makeNoDocument, buildState as buildNoDocumentState } from '@/store/no-document/store-index-no-document';
import { makeOtpLog, buildState as buildOtpLogState } from '@/store/otp/store-otp-log';
import { makeExclusive, buildState as buildExclusiveState } from '@/store/exclusive/store-exclusive';
import { makeSaleSheet, buildState as buildSaleSheetState } from '@/store/sale-sheet/store-sale-sheet';
import { makeCarousel, buildState as buildCarouselState } from '@/store/deal/store-carousel';
Vue.use(Vuex);
export var buildState = function () { return ({
    CustomerDetailStore: buildCustomerDetailState(),
    login: buildLoginState(),
    LoanApplication: buildLoanApplicationState(),
    EForm: buildEFormState(),
    deal: buildDealState(),
    noDocument: buildNoDocumentState(),
    customerInformation: buildCustomerInformationState(),
    otpLog: buildOtpLogState(),
    exclusive: buildExclusiveState(),
    saleSheet: buildSaleSheetState(),
    carousel: buildCarouselState(),
    lineka: buildLineKaState(),
}); };
export var actions = {
    reset: function (_a) {
        var commit = _a.commit, dispatch = _a.dispatch;
        commit('login/reset');
        commit('CustomerDetailStore/reset');
        commit('EForm/reset');
        commit('otpLog/reset');
        commit('exclusive/reset');
        commit('saleSheet/reset');
    },
};
export var makeStore = function (overrideState) {
    if (overrideState === void 0) { overrideState = {}; }
    return new Vuex.Store(makeStoreObj(overrideState));
};
export var makeStoreObj = function (overrideState) {
    if (overrideState === void 0) { overrideState = {}; }
    var modules = {
        CustomerDetailStore: CustomerDetailStore,
        login: makeLogin(),
        lineka: makeLineKa(),
        LoanApplication: LoanApplication,
        EForm: EForm,
        deal: deal,
        noDocument: makeNoDocument(),
        customerInformation: makeCustomerInformation(),
        otpLog: makeOtpLog(),
        exclusive: makeExclusive(),
        saleSheet: makeSaleSheet(),
        carousel: makeCarousel(),
    };
    return {
        modules: overrideModulesState(modules, overrideState),
        actions: actions,
    };
};
var overrideModuleState = function (singleModule, overrideState) {
    var stateKeys = _.keys(singleModule.state);
    var filteredOverrideState = _.pick(overrideState, stateKeys);
    return _.merge(_.cloneDeep(singleModule), { state: filteredOverrideState });
};
export var overrideModulesState = function (modules, overrideStates) {
    return _.mapValues(modules, function (module, moduleName) { return overrideModuleState(module, _.get(overrideStates, moduleName, {})); });
};
