export var buildState = function () { return ({
    subProduct: null,
}); };
export var getters = {
    isSelected: function (_a) {
        var subProduct = _a.subProduct;
        return subProduct !== null && subProduct.length > 0;
    },
    subProduct: function (_a) {
        var subProduct = _a.subProduct;
        return subProduct;
    },
};
export var actions = {
    update: function (_a, payload) {
        var commit = _a.commit;
        commit('update', { key: 'subProduct', value: payload });
    },
};
export var mutations = {
    update: function (state, _a) {
        var key = _a.key, value = _a.value;
        state[key] = value;
    },
    reset: function (state) {
        state.subProduct = null;
    },
};
export var makeProduct = function () { return ({
    namespaced: true,
    state: buildState(),
    getters: getters,
    actions: actions,
    mutations: mutations,
}); };
