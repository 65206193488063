import _ from 'lodash'
import {userStatus} from '@/api'
import {format} from 'date-fns'
import CryptoJS from 'crypto-js'

export const fillUserStatus = (store, to, from, _userStatus = userStatus) => {
  const isFirstLoad = !from.name
  const isToHome = to.name === 'home'
  const isFromHome = from && from.name === 'home'

  if ((isFirstLoad && !isToHome) || isFromHome) {
    return _userStatus()
      .then(({data}) => {
        store.commit('login/updateIsLoggedIn', true)
        store.commit('LoanApplication/updateLoanApplication', data.loanApplication)
      })
      .catch(() => {
        store.commit('login/updateIsLoggedIn', false)
        store.commit('LoanApplication/updateLoanApplication', null)
      })
  } else {
    return Promise.resolve()
  }
}

export function mergeObj (obj1, obj2) {
  return _.merge(_.cloneDeep(obj1), _.cloneDeep(obj2))
}

export function formatPhoneNumber (phoneNumber) {
  if (phoneNumber === null || phoneNumber === '') {
    return '--'
  }
  if (phoneNumber.length === 10) {
    return phoneNumber.replace(/^([0-9]{3})([0-9]{3})([0-9]{4})$/, '$1-$2-$3')
  }
  if (phoneNumber.startsWith('02')) {
    return phoneNumber.replace(/^([0-9])([0-9]{4})([0-9]{4})$/, '$1-$2-$3')
  }
  return phoneNumber.replace(/^([0-9]{3})([0-9]{3})([0-9]{3})$/, '$1-$2-$3')
}

export function formatCitizenId (citizenId) {
  if (citizenId === null || citizenId === '' || citizenId.length !== 13) {
    return citizenId
  }

  return citizenId.replace(/^([0-9]{1})([0-9]{4})([0-9]{5})([0-9]{2})([0-9]{1})$/, '$1-$2-$3-$4-$5')
}

export function formatNumberWithMaiximumDigits (value, digits) {
  return parseFloat(value.toString().match(new RegExp('^-?\\d+(?:\\.\\d{0,' + digits + '})?', 'g'))[0])
}

export function generateUniqueid () {
  const fixValue = 'BAY'
  const randomDigits = Math.floor(100000 + Math.random() * 900000)
  const now = format(new Date(), 'YYYYMMDD')
  const password = '8p5ymkPqNvCDQJGJyeZL32a7KJWJL93X'
  const vector = 'vXeTKVYfVY5FAUuA'
  const key = CryptoJS.enc.Utf8.parse(password)
  const iv = CryptoJS.enc.Utf8.parse(vector)
  const Uniqueid = fixValue + randomDigits + now
  const encryptedCP = CryptoJS.AES.encrypt(Uniqueid, key, {iv: iv})
  const encryptedBase64 = encryptedCP.toString()
  const encrypted = encodeURIComponent(encryptedBase64)
  return encrypted
}
