var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import _ from 'lodash';
var CAMPAIGN_KEYS = [
    'apply_source',
    'apply_medium',
    'apply_campaign',
    'apply_referral_code',
    'apply_campaign_code',
    'apply_sub_campaign',
    'apply_campaign_product',
    'ori_source',
    'ori_medium',
    'ori_campaign',
    'referral_code',
    'campaign_code',
    'sub_campaign',
    'campaign_product',
    'exclusive',
    'mobile',
    'reference_id',
    'partner',
];
function isGoingToSamePage(path, to) {
    return path.name === to.name;
}
function isValue(val) {
    return val != null;
}
function isNeedToBeCarry(fromQuery, toQuery) {
    if (fromQuery === void 0) { fromQuery = {}; }
    if (toQuery === void 0) { toQuery = {}; }
    return !!CAMPAIGN_KEYS.find(function (key) { return isValue(fromQuery[key]) && !isValue(toQuery[key]); });
}
export function getCarryCampaignDataObj(fromQuery) {
    if (fromQuery === void 0) { fromQuery = {}; }
    return CAMPAIGN_KEYS.filter(function (key) { return isValue(fromQuery[key]); }).reduce(function (obj, key) {
        var _a;
        return (__assign(__assign({}, obj), (_a = {}, _a[key] = fromQuery[key], _a)));
    }, {});
}
export default function buildNextWithCampaignCarry(_a) {
    var from = _a.from, to = _a.to, next = _a.next, isBrowserNavigation = _a.isBrowserNavigation;
    if (isBrowserNavigation) {
        return function (redirectToObj) {
            if (redirectToObj) {
                return next(_(__assign(__assign({}, redirectToObj), { query: __assign(__assign(__assign({}, from.query), to.query), redirectToObj.query) }))
                    .omitBy(_.isEmpty)
                    .value());
            }
            return next();
        };
    }
    else {
        return function (redirectToObj) {
            if (isGoingToSamePage(from, to)) {
                return next();
            }
            if (redirectToObj) {
                return next(__assign(__assign({}, redirectToObj), { query: __assign(__assign(__assign({}, from.query), to.query), redirectToObj.query) }));
            }
            if (isNeedToBeCarry(from.query, to.query)) {
                return next(__assign(__assign({}, to), { query: __assign(__assign({}, getCarryCampaignDataObj(from.query)), getOriginalQuery(to.query)) }));
            }
            if (_.size(to.query) > 0 && Object.keys(to.query).some(function (x) { return x.includes('utm'); })) {
                return next(__assign(__assign({}, to), { query: __assign({}, getOriginalQuery(to.query)) }));
            }
            return next();
        };
    }
}
export function getOriginalQuery(utmQuery) {
    var currentQuery = {};
    Object.keys(utmQuery).forEach(function (key) {
        currentQuery[key.replace('utm', 'ori')] = utmQuery[key];
    });
    var defaultApplyQuery = {
        apply_campaign: _.get(utmQuery, 'utm_campaign'),
        apply_sub_campaign: _.get(utmQuery, 'sub_campaign'),
        apply_medium: _.get(utmQuery, 'utm_medium'),
        apply_campaign_code: _.get(utmQuery, 'campaign_code'),
        apply_campaign_product: _.get(utmQuery, 'campaign_product'),
        apply_referral_code: _.get(utmQuery, 'referral_code'),
        apply_source: _.get(utmQuery, 'utm_source'),
    };
    return __assign(__assign({}, _.pickBy(defaultApplyQuery, _.identity)), currentQuery);
}
