var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import _ from 'lodash';
export var mutations = {
    updatecrossSalesPool: function (state, value) {
        state.crossSalesPool = value;
    },
    updateCustomerInfo: function (state, value) {
        state.customerInfo = value;
    },
    updateIsSpecialCrm: function (state, value) {
        state.specialCrm = value;
    },
    reset: function (state) {
        state.crossSalesPool = [];
        state.customerInfo = null;
    },
};
export var getters = {
    isExclusive: function (_a) {
        var crossSalesPool = _a.crossSalesPool;
        return crossSalesPool !== null && crossSalesPool.length > 0;
    },
    defaultValue: function (state) {
        return {
            title: _.get(state, 'customerInfo.title', null),
            thaiFirstName: _.get(state, 'customerInfo.thaiFirstName', null),
            thaiLastName: _.get(state, 'customerInfo.thaiLastName', null),
            enFirstName: _.get(state, 'customerInfo.enFirstName', null),
            enLastName: _.get(state, 'customerInfo.enLastName', null),
            dob: _.get(state, 'customerInfo.dob', null),
            citizenId: _.get(state, 'customerInfo.citizenId', null),
            mobileNumber: _.get(state, 'customerInfo.mobileNumber', null),
            email: _.get(state, 'customerInfo.email', null),
        };
    },
    getTermByProduct: function (_a) {
        var crossSalesPool = _a.crossSalesPool;
        return function (productType) {
            var crossSales = crossSalesPool.find(function (product) { return productType === product.product; });
            return _.sortBy(_.get(crossSales, 'creditLineTerms', null)).reverse();
        };
    },
    getCrossSalesPool: function (_a) {
        var crossSalesPool = _a.crossSalesPool;
        return function (productType) {
            var crossSales = crossSalesPool.find(function (product) { return productType === product.product; });
            var creditLineTerms = _.orderBy(_.get(crossSales, 'creditLineTerms', null), ['term'], ['asc']);
            return __assign(__assign({}, crossSales), { creditLineTerms: creditLineTerms });
        };
    },
};
export var actions = {
    updateExclusive: function (_a, value) {
        var commit = _a.commit;
        commit('updatecrossSalesPool', value.crossSalesPool);
        commit('updateCustomerInfo', value.customerInfo);
        commit('updateIsSpecialCrm', value.specialCrm);
    },
    reset: function (_a) {
        var commit = _a.commit;
        commit('reset');
    },
};
export var buildState = function () { return ({
    crossSalesPool: [],
    customerInfo: null,
    specialCrm: false,
}); };
export var makeExclusive = function () { return ({
    namespaced: true,
    state: buildState(),
    actions: actions,
    mutations: mutations,
    getters: getters,
}); };
