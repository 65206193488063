var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import _ from 'lodash';
import { loanStatusDispatcher } from '@/utilities/routing';
import buildNextWithCampaignCarry, { getCarryCampaignDataObj } from './with-carry';
import { format, isBefore, isAfter } from 'date-fns';
import { CampaignProductType, getProductNameByCampaignProductName } from '@/utilities/product-types';
import { DealType } from '@/utilities/deal-types';
import { LOAN_STATUS } from '@/utilities/Constants';
import { CrmUsed } from '@/utilities/exclude-campaigns';
function isGoingToSamePage(path, to) {
    return path.name === to.name;
}
export var globalGuardFactory = function (store, api, features) {
    var globalGuard = function (to, from, next) { return __awaiter(void 0, void 0, void 0, function () {
        var isBrowserNavigation, patchedNext;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (from.name === 'MissingData' && to.name !== null && to.name !== 'productHighlight' && to.query.exclusive === 'Y') {
                        window.history.go(-1);
                        next({ name: 'productHighlight', query: getCarryCampaignDataObj(from.query) });
                        return [2 /*return*/];
                    }
                    else if (from.name === 'productHighlight' && to.name === 'productHighlight') {
                        window.history.go(-1);
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, fetchInfo(to, from)];
                case 1:
                    _a.sent();
                    isBrowserNavigation = window.popStateDetected;
                    window.popStateDetected = false;
                    patchedNext = buildNextWithCampaignCarry({
                        from: from,
                        to: to,
                        next: next,
                        isBrowserNavigation: isBrowserNavigation,
                    });
                    desktopGuard(to, patchedNext, features) &&
                        sessionGuard(to, from, patchedNext) &&
                        webViewGuard(to, from, patchedNext, store) &&
                        specialCrmDealGuard(to, from, patchedNext, store) &&
                        loanGuard(to, from, patchedNext) &&
                        directAccessGuard(to, patchedNext) &&
                        invalidDealGuard(to, patchedNext) &&
                        refreshGuard(to, from, patchedNext, store, features) &&
                        patchedNext();
                    return [2 /*return*/];
            }
        });
    }); };
    var webViewGuard = function (to, from, next, store) {
        if (to.meta.routeConfig.webViewGuard) {
            if (to.name === 'VerifyExclusiveOtp' && from.name === 'DealListExclusive') {
                window.history.go(-2);
            }
        }
        return true;
    };
    var specialCrmDealGuard = function (to, from, next, store) {
        var utm = getCarryCampaignDataObj(to.query);
        var isSpecialCrm = store.state.exclusive.specialCrm;
        var crmUsedCarDeal = CrmUsed.includes("" + utm.ori_campaign);
        var isCrmUsedCarDeal = utm.campaign_product === CampaignProductType.CAMPAIGN_USED_CAR && utm.ori_source === 'CRM';
        if ((to.name === 'DealList' || to.name === 'DealListExclusive') && isSpecialCrm && isCrmUsedCarDeal && utm.mobile !== 'true') {
            next({
                name: 'DealDetail',
                params: { campaignName: utm.ori_campaign },
            });
            return false;
        }
        if ((to.name === 'DealDetail' || to.name === 'ExclusiveDealDetail') && isCrmUsedCarDeal && (!isSpecialCrm && store.state.LoanApplication.loanApplication === null)) {
            next({
                name: 'productHighlight',
            });
            return false;
        }
        return true;
    };
    var fetchInfo = function (to, from) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!to.meta.routeConfig.fetchSession) return [3 /*break*/, 2];
                    return [4 /*yield*/, store.dispatch('noDocument/login/refreshSession', { api: api })];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2:
                    if (!to.meta.routeConfig.fetchApp) return [3 /*break*/, 5];
                    return [4 /*yield*/, getUserExclusive(to, from)];
                case 3:
                    _a.sent();
                    return [4 /*yield*/, getActiveLoan(to, from)];
                case 4:
                    _a.sent();
                    _a.label = 5;
                case 5:
                    if (!to.meta.routeConfig.fetchDeal) return [3 /*break*/, 7];
                    return [4 /*yield*/, store.dispatch('deal/refreshDealList', { api: api })];
                case 6:
                    _a.sent();
                    _a.label = 7;
                case 7: return [2 /*return*/];
            }
        });
    }); };
    var refreshGuard = function (to, from, next, store, features) {
        if (to.meta.routeConfig.redirectWhenRefresh) {
            var isRefresh = from.name === null && to.name !== null;
            var isStoreInvalidProduct = !store.getters['noDocument/product/isSelected'];
            var isSelectedDeal = store.getters['deal/getSelectedDeal'];
            if (isSelectedDeal && isSelectedDeal.type === DealType.EXCLUSIVE) {
                return true;
            }
            if (isRefresh || isStoreInvalidProduct) {
                next({ name: 'MissingData' });
                return false;
            }
        }
        return true;
    };
    var invalidDealGuard = function (to, next) {
        var dealList = store.getters['deal/dealList'];
        if (dealList && dealList.length !== 0 && to.name === 'DealDetail' && to.params.campaignName) {
            var dealDetail = dealList.find(function (item) { return item.campaignName === to.params.campaignName; });
            if (dealDetail) {
                var started = isDealStart(dealDetail);
                if (!started) {
                    next({ name: 'pageNotFound' });
                    return false;
                }
            }
        }
        if (to.meta.routeConfig.allowInvalidDeal) {
            return true;
        }
        var utm = getCarryCampaignDataObj(to.query);
        var campaign = utm.apply_campaign;
        if (campaign) {
            if (dealList && dealList.length !== 0) {
                var dealDetail = dealList.find(function (item) { return item.campaignName === campaign && (item.subCampaign === '' || item.subCampaign === _.get(store.state.deal.selectedDeal, 'subCampaign', '')); });
                if (dealDetail) {
                    var outOfstock = !dealDetail.dealIsExist;
                    var expired = isExpired(dealDetail);
                    var started = isDealStart(dealDetail);
                    if (!started) {
                        onPageNotFound(to) ? next() : next({ name: 'pageNotFound' });
                        return false;
                    }
                    if (expired || outOfstock) {
                        onSamePage(to, dealDetail)
                            ? next()
                            : next({
                                name: 'DealDetail',
                                params: { campaignName: dealDetail.campaignName },
                            });
                        return false;
                    }
                }
            }
        }
        return true;
    };
    function isExpired(dealDetail) {
        var dealEndDate = format(new Date(dealDetail.endDate), 'YYYY-MM-DD');
        var today = format(new Date(), 'YYYY-MM-DD');
        return isAfter(today, dealEndDate);
    }
    function isDealStart(dealDetail) {
        var dealStartDate = dealDetail.startDate.toString();
        return !isBefore(format(new Date(), 'YYYY-MM-DD'), format(new Date(dealStartDate), 'YYYY-MM-DD'));
    }
    function onSamePage(to, dealDetail) {
        return to.name === 'DealDetail' && _.get(to, 'params.campaignName') === dealDetail.campaignName;
    }
    function onPageNotFound(to) {
        return to.name === 'pageNotFound';
    }
    var desktopGuard = function (to, next, features) {
        if (!features.DESKTOP_VERSION) {
            if (isDesktop() && !to.meta.routeConfig.allowDesktop) {
                next({ name: 'desktopLanding' });
                return false;
            }
            return true;
        }
        return features.DESKTOP_VERSION;
    };
    var sessionGuard = function (to, from, next) {
        var query = getCarryCampaignDataObj(to.query);
        if (to.meta.routeConfig.allowAnonymous) {
            return true;
        }
        if (store.state.noDocument.login.isLoggedIn) {
            return true;
        }
        if (query.exclusive === 'Y') {
            next({ name: 'RequestExclusiveOtp' });
        }
        else {
            next({ name: 'login', params: { isSessionExpired: true } });
        }
        return false;
    };
    var loanGuard = function (to, from, next) {
        if (!to.meta.routeConfig.redirectWhenCustomerHasLoan) {
            return true;
        }
        if (!store.state.LoanApplication.loanApplication) {
            return true;
        }
        var path = loanStatusDispatcher(store.state.LoanApplication.loanApplication);
        if (!path) {
            next({ name: 'pageNotFound' });
            return false;
        }
        if (isGoingToSamePage(path, to) || path.name === 'ReUpload') {
            next();
            return false;
        }
        next(path);
        return false;
    };
    var directAccessGuard = function (to, next) {
        if (to.meta.routeConfig.allowDirect || _.get(to, 'params.actionNavigate')) {
            return true;
        }
        var fallbackPage = getFallbackPage(to);
        next(fallbackPage);
        return false;
    };
    var getFallbackPage = function (to) {
        var loanApplication = store.state.LoanApplication.loanApplication;
        if (loanApplication) {
            return loanStatusDispatcher(loanApplication);
        }
        return { name: to.meta.routeConfig.fallbackPage };
    };
    var isDesktop = function () {
        var userAgent = navigator.userAgent.toLocaleLowerCase();
        return !(userAgent.includes('mobile') || userAgent.includes('android'));
    };
    var getActiveLoan = function (to, from) { return __awaiter(void 0, void 0, void 0, function () {
        var toName, fromName, isFirstLoad, isToHome, isFromHome, loanStatusBeforeAfsFeedback, isNotPreApprovedCallUserStatus, response, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    toName = _.get(to, 'name');
                    fromName = _.get(from, 'name');
                    isFirstLoad = !fromName;
                    isToHome = toName === 'home' || fromName === 'homePage';
                    isFromHome = fromName === 'home' || fromName === 'homePage';
                    loanStatusBeforeAfsFeedback = store.getters['LoanApplication/getApplicationStatus'];
                    isNotPreApprovedCallUserStatus = toName === 'ExclusivePreApproval' && loanStatusBeforeAfsFeedback !== LOAN_STATUS.PRE_APPROVED;
                    if (!((isFirstLoad && !isToHome) || isFromHome || isNotPreApprovedCallUserStatus)) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, api.userStatus()];
                case 2:
                    response = _a.sent();
                    store.commit('login/updateIsLoggedIn', true);
                    store.commit('LoanApplication/updateLoanApplication', response.data.loanApplication);
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    store.commit('login/updateIsLoggedIn', false);
                    store.commit('LoanApplication/updateLoanApplication', null);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var getUserExclusive = function (to, from) { return __awaiter(void 0, void 0, void 0, function () {
        var utm, toName, fromName, isFirstLoad, isToHome, isFromHome, res, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    utm = getCarryCampaignDataObj(to.query);
                    toName = _.get(to, 'name');
                    fromName = _.get(from, 'name');
                    isFirstLoad = !fromName;
                    isToHome = toName === 'productHighlight';
                    isFromHome = fromName === 'productHighlight';
                    if (!((isFirstLoad && !isToHome) || isFromHome)) return [3 /*break*/, 4];
                    if (!(utm.exclusive === 'Y' && store.state.exclusive.customerInfo == null)) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, api.userExclusive()];
                case 2:
                    res = _a.sent();
                    store.commit('login/updateIsCrmLoggedIn', true);
                    store.dispatch('exclusive/updateExclusive', res.data);
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    store.commit('login/updateIsCrmLoggedIn', false);
                    store.dispatch('exclusive/updateExclusive', { crossSalesPool: [], customerInfo: null, specialCrm: false });
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return globalGuard;
};
export var selectProductGuard = function (store) {
    var guard = function (to, from, next) { return __awaiter(void 0, void 0, void 0, function () {
        var isBrowserNavigation, patchedNext, applyProduct, productType_1, productName;
        return __generator(this, function (_a) {
            isBrowserNavigation = window.popStateDetected;
            window.popStateDetected = false;
            patchedNext = buildNextWithCampaignCarry({
                from: from,
                to: to,
                next: next,
                isBrowserNavigation: isBrowserNavigation,
            });
            applyProduct = null;
            if (from.query.apply_campaign_product || to.query.apply_campaign_product) {
                productType_1 = to.query.apply_campaign_product ? to.query.apply_campaign_product : from.query.apply_campaign_product;
                Object.keys(CampaignProductType).forEach(function (key) {
                    if (CampaignProductType[key] === productType_1) {
                        applyProduct = CampaignProductType[key];
                        return true;
                    }
                    return false;
                });
                productName = getProductNameByCampaignProductName(applyProduct);
                if (productName) {
                    store.dispatch('noDocument/product/update', productName);
                }
            }
            if (applyProduct !== null) {
                if (to.query.exclusive === 'Y' && _.get(store, 'state.deal.selectedDeal.type', null) === DealType.EXCLUSIVE) {
                    patchedNext({ name: 'PersonalExclusiveInfomation' });
                }
                else {
                    patchedNext({ name: 'PersonalInfo' });
                }
            }
            else {
                patchedNext();
            }
            return [2 /*return*/];
        });
    }); };
    return guard;
};
